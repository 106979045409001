'use client';
/* eslint-disable */

import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Spinner,  useDisclosure,Menu, MenuButton, MenuList, MenuItem, IconButton,AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter,Button
} from '@chakra-ui/react';
import { useRef } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { SpinnerIcon } from '@chakra-ui/icons';
// Custom components
import * as React from 'react';
import Card from 'components/card/Card';
import useSlot from 'hooks/slotApis/useSlot';
import usePagination from 'hooks/usePagination';
import useSlotActions from 'hooks/slotApis/useSlotActions';
import ReusableTable from 'components/reusableTable/ReusableTable';
import AddSlotPopup from 'components/popups/slotPopup/AddSlotPopup';
import Pagination from '../../../../components/pagination/Pagination';
import EditButton from 'components/popups/editButtonPopup/EditButton';
import DeleteButton from 'components/popups/deleteButtonPopup/DeleteButton';
import SwitchToggler from 'components/popups/updateStatusPopup/SwitchToggler';
import EditSlotDetails from 'components/popups/slotPopup/EditSlotDetails';
import { useSearch } from 'contexts/SearchContext';
import { FiMoreVertical } from 'react-icons/fi';
// Assets

const columnHelper = createColumnHelper();
const ITEMS_PER_PAGE = 10;

export default function ComplexTable({ isOpen, onClose }) {
  const toast = useToast({ position: 'top' });
  const { slots, error, fetchSlots, registerSlot } = useSlot();
  const { deleteSlot, updateSlotDetails } = useSlotActions();
  const { searchResults, searchQuery } = useSearch();
  // console.log(searchResults);

  const totalPages = searchQuery
    ? searchResults.totalPages || 1
    : slots.totalPages || 1;
  const { currentPage, handlePageChange } = usePagination(totalPages);

  React.useEffect(() => {
    if (!searchQuery) {
      fetchSlots(currentPage);
    }
  }, [fetchSlots, currentPage, searchQuery]);

  const handleAddBanner = async (formData) => {
    try {
      await registerSlot(formData);
      await fetchSlots(currentPage);
    } catch (error) {
      toast({
        title: 'Adding Failed',
        description: error.message || 'Failed to add slot.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditBanner = async (slotId, userData) => {
    try {
      await updateSlotDetails(slotId, userData);
      await fetchSlots(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteSlot(id);
      await fetchSlots(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to delete slot.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const data = React.useMemo(() => {
    if (searchQuery && searchResults.data?.length > 0) {
      return searchResults.data;
    }
    return slots.data || [];
  }, [slots.data, searchResults.data, searchQuery]);

  const columns = [
    columnHelper.accessor('sl. no.', {
      id: 'sl. no.',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SL. NO.
        </Text>
      ),
      cell: ({ row }) => {
        const serialNumber = (currentPage - 1) * ITEMS_PER_PAGE + row.index + 1;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {serialNumber}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('startTime', {
      id: 'startTime',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
          whiteSpace="nowrap"
        >
          TIME SLOT
        </Text>
      ),
      cell: ({ row }) => {
        const startTime = row.original.startTime;
        const clockCycle = row.original.clockCycle;

        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {startTime} {clockCycle}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('isActive', {
      id: 'isActive',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          STATUS
        </Text>
      ),
      cell: ({ row }) => (
        <Flex align="center">
          <SwitchToggler
            entityType="slot"
            entityId={row.original._id}
            initialStatus={row.original.isActive}
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ACTION
        </Text>
      ),
      cell: ({ row }) => {
                   const { isOpen, onOpen, onClose } = useDisclosure();
                   const cancelRef = useRef();
        return (
          <Flex align="center">
            <EditButton
              entityType="banner"
              EditComponent={EditSlotDetails}
              initialData={row.original}
              onConfirm={(updatedData) =>
                handleEditBanner(row.original._id, updatedData)
              }
            />
           <Menu strategy="fixed" closeOnSelect={false}>
                                   <MenuButton
                                     as={IconButton}
                                     icon={<FiMoreVertical />}
                                     variant="ghost"
                                     aria-label="Options"
                                   />
                                   <MenuList>
                                     {!row.original.isDeleted && (
                                       <MenuItem color="red.500" onClick={onOpen}>
                                         Delete
                                       </MenuItem>
                                     )}
                                   </MenuList>
                                 </Menu>
                           
                                 {/* Confirmation Dialog for Deletion */}
                                 <AlertDialog
                                   isOpen={isOpen}
                                   leastDestructiveRef={cancelRef}
                                   onClose={onClose}
                                 >
                                   <AlertDialogOverlay>
                                     <AlertDialogContent>
                                       <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                         Confirm Delete
                                       </AlertDialogHeader>
                           
                                       <AlertDialogBody>
                                         Are you sure you want to delete this Slot?
                                       </AlertDialogBody>
                           
                                       <AlertDialogFooter>
                                         <Button ref={cancelRef} onClick={onClose}>
                                           Cancel
                                         </Button>
                                         <Button
                                           colorScheme="red"
                                           onClick={() => {
                                             handleDelete(row.original._id);
                                             onClose();
                                           }}
                                           ml={3}
                                         >
                                           Delete
                                         </Button>
                                       </AlertDialogFooter>
                                     </AlertDialogContent>
                                   </AlertDialogOverlay>
                                 </AlertDialog>
                               </Flex>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  
  if (error)
    return toast({
      title: 'Error',
      description: slots.message || 'Failed to fetch slots.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p="0px"
        maxH="60vh"
        minH="auto"
        overflowX="auto"
        overflowY="auto"
      >
        <ReusableTable
          table={table}
          data={data}
          borderColor="gray.200"
          noDataText="No records found."
          bgColor="aliceblue"
          isRowDeleted={(row) => row.original.isDeleted}
          rowBgColor="gray.200"
          rowTextColor="gray.600"
        />
        <AddSlotPopup
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleAddBanner}
        />
      </Card>
      {data.length === 0 ? (
        <></>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
