'use client';
/* eslint-disable */

import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Spinner, Menu, MenuButton, MenuList, MenuItem, IconButton,AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter,Button
} from '@chakra-ui/react';
import { useDisclosure } from "@chakra-ui/react";
import { useRef } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import Card from 'components/card/Card';
import usePagination from 'hooks/usePagination';
import useBanner from 'hooks/bannerApis/useBanner';
import useBannerActions from 'hooks/bannerApis/useBannerActions';
import ReusableTable from 'components/reusableTable/ReusableTable';
import BannerPopup from 'components/popups/bannerPopup/BannerPopup';
import Pagination from '../../../../components/pagination/Pagination';
import EditButton from 'components/popups/editButtonPopup/EditButton';
import DeleteButton from 'components/popups/deleteButtonPopup/DeleteButton';
import SwitchToggler from 'components/popups/updateStatusPopup/SwitchToggler';
import EditBannerDetails from 'components/popups/bannerPopup/EditeBannerDetails';
import GenericDetailView from 'components/popups/GenericDetailView/GenericDetailView';
import { FiMoreVertical } from 'react-icons/fi';
// Assets

const columnHelper = createColumnHelper();
const ITEMS_PER_PAGE = 10;

export default function ComplexTable({ isOpen, onClose }) {
  const toast = useToast({ position: 'top' });
  const { banners, error, fetchBanner, registerBanner } = useBanner();
  const { deleteBanner, updateBannerDetails } = useBannerActions();
  const totalPages = banners.totalPages || 1;
  const { currentPage, handlePageChange } = usePagination(totalPages);

  React.useEffect(() => {
    fetchBanner(currentPage);
  }, [fetchBanner, currentPage]);

  const handleAddBanner = async (formData) => {
    try {
      await registerBanner(formData);
      await fetchBanner(currentPage);
    } catch (error) {
      toast({
        title: 'Adding Failed',
        description: error.message || 'Failed to add banner.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditBanner = async (bannerId, userData) => {
    try {
      await updateBannerDetails(bannerId, userData);
      await fetchBanner(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update banner details.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteBanner(id);
      await fetchBanner(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to delete banner.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const data = React.useMemo(() => banners.data || [], [banners.data]);

  const columns = [
    columnHelper.accessor('sl. no.', {
      id: 'sl. no.',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SL. NO.
        </Text>
      ),
      cell: ({ row }) => {
        const serialNumber = (currentPage - 1) * ITEMS_PER_PAGE + row.index + 1;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {serialNumber}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('image', {
      id: 'image',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          IMAGE
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <GenericDetailView
            type="image"
            content={info.getValue()}
            title="Image Preview"
            imageAlt="Banner Image"
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('redirectUrl', {
      id: 'redirectUrl',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          REDIRECT URL
        </Text>
      ),
      cell: (info) => {
        const redirectUrl = info.getValue();
        return (
          <Flex align="center">
            <Text
              color={'blue'}
              fontSize="sm"
              fontWeight="700"
              whiteSpace="nowrap"
            >
              {redirectUrl ? redirectUrl : 'NA'}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('position', {
      id: 'position',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          POSITION
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('isActive', {
      id: 'isActive',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          STATUS
        </Text>
      ),
      cell: ({ row }) => (
        <Flex align="center">
          <SwitchToggler
            entityType="banner"
            entityId={row.original._id}
            initialStatus={row.original.isActive}
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ACTION
        </Text>
      ),
       cell: ({ row }) => {
             const { isOpen, onOpen, onClose } = useDisclosure();
             const cancelRef = useRef();
             
        return (
          <Flex align="center">
            <EditButton
              entityType="banner"
              EditComponent={EditBannerDetails}
              initialData={row.original}
              onConfirm={(updatedData) =>
                handleEditBanner(row.original._id, updatedData)
              }
            />
            <Menu strategy="fixed" closeOnSelect={false}>
              <MenuButton
                as={IconButton}
                icon={<FiMoreVertical />}
                variant="ghost"
                aria-label="Options"
              />
              <MenuList>
                {!row.original.isDeleted && (
                  <MenuItem color="red.500" onClick={onOpen}>
                    Delete
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
      
            {/* Confirmation Dialog for Deletion */}
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Confirm Delete
                  </AlertDialogHeader>
      
                  <AlertDialogBody>
                    Are you sure you want to delete this banner?
                  </AlertDialogBody>
      
                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        handleDelete(row.original._id);
                        onClose();
                      }}
                      ml={3}
                    >
                      Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Flex>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: banners?.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error)
    return toast({
      title: 'Error',
      description: banners.message || 'Failed to fetch banners',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p="0px"
        maxH="60vh"
        minH="auto"
        overflowX="auto"
        overflowY="auto"
      >
        <ReusableTable
          table={table}
          data={data}
          borderColor="gray.200"
          noDataText="No records found."
          bgColor="aliceblue"
          isRowDeleted={(row) => row.original.isDeleted}
          rowBgColor="gray.200"
          rowTextColor="gray.600"
        />
        <BannerPopup
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleAddBanner}
        />
      </Card>
      {data.length === 0 ? (
        <></>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
