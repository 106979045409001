'use client';
/* eslint-disable */

import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Button,
  useDisclosure,Menu, MenuButton, MenuList, MenuItem, IconButton,AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter
} from '@chakra-ui/react';
import { useRef } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import Card from 'components/card/Card';
import usePagination from 'hooks/usePagination';
import convertDateFormat from 'utils/extractDate';
import useBooking from 'hooks/bookingApis/useBooking';
import ReusableTable from 'components/reusableTable/ReusableTable';
import Pagination from '../../../../components/pagination/Pagination';
import AssignPartnerPopup from 'components/popups/partnerPopup/AssignPartnerPopup';
import GenericDetailView from 'components/popups/GenericDetailView/GenericDetailView';
import { useSearch } from 'contexts/SearchContext';
import usePartner from 'hooks/partnerApis/usePartner';
import { FiMoreVertical } from 'react-icons/fi';
// Assets

const columnHelper = createColumnHelper();
const ITEMS_PER_PAGE = 10;

export default function ComplexTable() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast({ position: 'top' });
  const { fetchPartners, error, partners } = usePartner();
  const { booking, assignPartner, fetchBooking } = useBooking();
  const { searchQuery, setSearchQuery, searchResults, setSearchResults, clearSearchResults } = useSearch();
     const [localSearch, setLocalSearch] = React.useState(searchQuery);
  const totalPages = searchQuery
    ? searchResults.totalPages || 1
    : booking.totalPages || 1;
  const { currentPage } = usePagination(totalPages);


 React.useEffect(() => {
   fetchBooking(1, searchQuery);
 }, [searchQuery]);
 const handlePageChange = (newPage) => {
   fetchBooking(newPage, searchQuery);
 };

  const handleAssignPartner = async (selectedPartnerId, bookingId) => {
    try {
      await assignPartner(selectedPartnerId, bookingId);
      await fetchBooking(currentPage);
    } catch (error) {
      toast({
        title: 'Assign Failed',
        description: error.message || 'Failed to assign partner.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const data = React.useMemo(() => {
    if (searchQuery && searchResults.data?.length > 0) {
      return searchResults.data;
    }
    return booking.data || [];
  }, [booking.data, searchQuery, searchResults.data]);

  const columns = [
    columnHelper.accessor('sl. no.', {
      id: 'sl. no.',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SL. NO.
        </Text>
      ),
      cell: ({ row }) => {
        const serialNumber = (currentPage - 1) * ITEMS_PER_PAGE + row.index + 1;
        return (
          <Flex align="center">
            <Text width="35px" color={textColor} fontSize="sm" fontWeight="700">
              {serialNumber}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          NAME
        </Text>
      ),
      cell: ({ row }) => {
        const customerName = row.original.customer?.name;

        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              whiteSpace="nowrap"
            >
              {customerName || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('number', {
      id: 'number',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          NUMBER
        </Text>
      ),
      cell: ({ row }) => {
        const number = row.original.customer?.mobile;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {number || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('email', {
      id: 'email',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          EMAIL
        </Text>
      ),
      cell: ({ row }) => {
        const email = row.original.customer?.email;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {email || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('_id', {
      id: '_id',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          BOOKING ID
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          BOOKED ON
        </Text>
      ),
      cell: (info) => {
        const formattedDate = convertDateFormat(info.getValue());
        return (
          <Flex align="center">
            <Text width="90px" color={textColor} fontSize="sm" fontWeight="700">
              {formattedDate || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('scheduleFor', {
      id: 'scheduleFor',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          BOOKED FOR
        </Text>
      ),
      cell: ({ row }) => {
        const originalDate = row.original.scheduleFor?.date;
        const originalTime =
          row.original.scheduleFor?.time +
          ' ' +
          row.original.scheduleFor?.format;
        const formattedDate = convertDateFormat(originalDate);

        return (
          <Flex align="center">
            <Text
              width="90px"
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              whiteSpace="nowrap"
            >
              {formattedDate + ' ' + originalTime || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('productName', {
      id: 'productName',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          PRODUCT NAME
        </Text>
      ),
      cell: ({ row }) => {
        const products = row.original.product || [];

        // Combine product names into a single comma-separated string
        const productNames = products
          .map((product) => product.product?.name || 'NA')
          .join(', ');

        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              width="120px"
            >
              <GenericDetailView
                type="text"
                content={productNames || 'NA'}
                title="Brand Name"
                maxChars={15}
                truncateType="chars"
              />
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('rating', {
      id: 'rating',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          rating
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('discountType', {
      id: 'discountType',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          DISCOUNT TYPE
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text width="100px" color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('totalPrice', {
      id: 'totalPrice',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SUBTOTAL
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            ₹ {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('discount', {
      id: 'discount',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          DISCOUNT
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            ₹ {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('finalPrice', {
      id: 'finalPrice',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          TOTAL PRICE
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text width="90px" color={textColor} fontSize="sm" fontWeight="700">
            ₹ {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ACTION
        </Text>
      ),
      cell: ({ row }) => {
        const partner = row.original.partner; // Access the partner data
        const bookingId = row.original._id;
        // console.log(bookingId);

        return (
          <Flex align="center">
            {partner && partner.length > 0 ? (
              <Text
                backgroundColor="red"
                textColor="white"
                borderRadius="50px"
                padding="0.3rem"
                fontSize="sm"
                fontWeight="700"
              >
                Assigned
              </Text>
            ) : (
              <>
                <Button colorScheme="blue" size="sm" onClick={onOpen}>
                  Assign
                </Button>

                {/* Assign Partner Popup */}
                <AssignPartnerPopup
                  isOpen={isOpen}
                  onClose={onClose}
                  partners={partners}
                  onAssign={(selectedPartnerId) =>
                    handleAssignPartner(selectedPartnerId, bookingId)
                  }
                />
              </>
            )}
          </Flex>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error)
    return toast({
      title: 'Error',
      description: booking.message || 'Failed to fetch booking.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p="0px"
        maxH="60vh"
        minH="auto"
        overflowX="auto"
        overflowY="auto"
      >
        <ReusableTable
          table={table}
          data={data}
          borderColor="gray.200"
          noDataText="No records found."
          bgColor="aliceblue"
          isRowDeleted={(row) => row.original.isDeleted}
          rowBgColor="gray.200"
          rowTextColor="gray.600"
        />
      </Card>
      {data.length === 0 ? (
        <></>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
