import { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  CheckboxGroup,
  FormErrorMessage,
  Menu,
  MenuButton,
  MenuList,
  Button,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const EditAdminDetails = ({ formData = {}, setFormData, isSuperAdmin, errors = {} }) => {
  const [selectedRoles, setSelectedRoles] = useState([]);

  // Convert comma-separated string to an array when component mounts
  useEffect(() => {
    if (typeof formData?.role === "string" && formData.role.length > 0) {
      setSelectedRoles(formData.role.split(","));
    } else if (typeof formData?.initialRoles === "string" && formData.initialRoles.length > 0) {
      setSelectedRoles(formData.initialRoles.split(","));
    }
  }, [formData?.role, formData?.initialRoles]);

  // Update formData when selectedRoles change
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      role: selectedRoles.join(","), // Store roles as a comma-separated string
    }));
  }, [selectedRoles, setFormData]);

  // Handle text input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const roles = [
    "customer_manager",
    "partner_manager",
    "stock_manager",
    "category_manager",
    "banner_manager",
    "product_manager",
    "membership_manager",
    "feedback_manager",
    "complaint_manager",
    "notification_manager",
    "offer_manager",
    "slot_manager",
    "booking_manager",
    "partner_booking_manager",
    "subcategory_manager",
  ];

  return (
    <>
      {/* Name */}
      <FormControl isRequired mb={4} isInvalid={!!errors.name}>
        <FormLabel>Name</FormLabel>
        <Input
          type="text"
          name="name"
          value={formData.name || ""}
          onChange={handleInputChange}
          placeholder="Enter name"
          focusBorderColor="blue.500"
        />
        <FormErrorMessage>{errors.name}</FormErrorMessage>
      </FormControl>

      {/* Email */}
      <FormControl isRequired mb={4} isInvalid={!!errors.email}>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          name="email"
          value={formData.email || ""}
          onChange={handleInputChange}
          placeholder="Enter email"
          focusBorderColor="blue.500"
        />
        <FormErrorMessage>{errors.email}</FormErrorMessage>
      </FormControl>

      {/* Username */}
      <FormControl isRequired mb={4} isInvalid={!!errors.username}>
        <FormLabel>Username</FormLabel>
        <Input
          type="text"
          name="username"
          value={formData.username || ""}
          onChange={handleInputChange}
          placeholder="Enter username"
          focusBorderColor="blue.500"
        />
        <FormErrorMessage>{errors.username}</FormErrorMessage>
      </FormControl>

      {/* Role Selection */}
      <FormControl isInvalid={!!errors.role} mb={3}>
        <FormLabel>Role</FormLabel>
        <Menu>
          <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
            Select Role
          </MenuButton>
          <MenuList maxH="200px" overflowY="auto" p={2}>
            <CheckboxGroup value={selectedRoles} onChange={setSelectedRoles}>
              <Stack direction="column">
                {roles.map((role) => (
                  <Checkbox key={role} value={role}>
                    {role.replace("_", " ").replace(/\b\w/g, (char) => char.toUpperCase())}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </MenuList>
        </Menu>
        <FormErrorMessage>{errors.role}</FormErrorMessage>
      </FormControl>
    </>
  );
};

export default EditAdminDetails;
