import { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  FormErrorMessage,
} from '@chakra-ui/react';

const AddSlotPopup = ({ isOpen, onClose, onConfirm, isLoading }) => {
  const [formData, setFormData] = useState({
    startTime: '',
    clockCycle: 'AM',
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (!value.trim()) {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const isDisabled = !formData.startTime.trim();

  const handleSubmit = async () => {
    if (!isDisabled) {
      try {
        await onConfirm(formData);
        onClose();
      } catch (error) {
        console.error('Error adding slot:', error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Slot</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={!!errors.startTime} mb={3}>
            <FormLabel>Start Time</FormLabel>
            <Input name="startTime" type="time" value={formData.startTime} onChange={handleInputChange} />
            <FormErrorMessage>{errors.startTime}</FormErrorMessage>
          </FormControl>
          <FormControl mb={3}>
            <FormLabel>Clock Cycle</FormLabel>
            <Select name="clockCycle" value={formData.clockCycle} onChange={handleInputChange}>
              <option value="AM">AM</option>
              <option value="PM">PM</option>
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button colorScheme="blue" onClick={handleSubmit} isLoading={isLoading} isDisabled={isDisabled}>
            Add Slot
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddSlotPopup;
