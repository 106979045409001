import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';
import { useLoading } from 'contexts/LoadingContext';

const useAdminActions = () => {
  const { setIsLoading } = useLoading();
  const toast = useToast({ position: "top" });
  const { token, user } = useAuth();

  const updateAdminDetails = async (id, updates) => {
    if (!token) {
      toast({
        title: "Authentication Error",
        description: "User token is missing. Please log in again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }

    try {
      console.log("Sending request to update admin details...");
      console.log("Token being sent:", token);

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/update?superadminId=${user.id}&adminId=${id}`, // ✅ Query params used
        updates, // ✅ Only updated fields in body (dynamic)
        {
          headers: {
            Authorization: ` ${token}`, 
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response:", response.data);

      if (response.data.success) {
        // toast({
        //   title: "",
        //   description: "",
        //   status: "success",
        //   duration: 3000,
        //   isClosable: true,
        // });
        return true;
      } else {
        toast({
          title: "Update Failed",
          description: response.data.message || "Failed to update admin details.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      console.error("Error updating admin:", error.response?.data || error.message);

      if (error.response?.status === 401) {
        toast({
          title: "Authentication Failed",
          description: "Your session has expired. Please log in again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Update Failed",
          description:
            error.response?.data?.message ||
            error.message ||
            "Failed to update admin details.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }

      return false;
    }
  };


  
  const updateAdminStatus = async (id, newStatus) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/toggle/status/?Id=${id}&superadminId=${user.id}`,
        { isActive: newStatus },
        {
          headers: {
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        toast({
          title: 'Status Updated',
          description: 'Admin status has been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          title: 'Update Failed',
          description:
            response.data.message || 'Failed to update admin status.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      console.error('Error updating status:', error);
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update admin status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
  };

  const deleteAdmin = async (id) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/delete/?Id=${id}&superadminId=${user.id}`,
        { isDeleted: true },
        {
          headers: {
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        toast({
          title: 'Admin Deleted',
          description:
            response.data.message || 'Admin has been deleted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          // title:
          //   'Account is already suspended, kindly contact the support team',
          description:
            response.data.message || 'Admin has been deleted successfully.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error deleting admin:', error);
      toast({
        title: 'Delete Failed',
        description: error.message || 'Failed to delete admin.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const downloadExcel = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/download-excel/?superadminId=${user.id}`,
        {
          responseType: 'blob',
        },
      );

      // Create a link element to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'admins.csv'); // Adjust the file name and extension as needed
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      if (response.data.success) {
        // Success toast
        toast({
          title: 'Download successful!',
          description: 'Admin data has been downloaded.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          title: 'Download Failed',
          description:
            response.data.message || 'Failed to download admin data.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      // Error toast
      toast({
        title: 'Download failed!',
        description: 'Something went wrong while downloading the admin data.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return { updateAdminStatus, deleteAdmin, downloadExcel ,updateAdminDetails};
};

export default useAdminActions;
