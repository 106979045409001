import { useState, useCallback } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useLoading } from 'contexts/LoadingContext';
import { useSearch } from 'contexts/SearchContext';

const useNotification = () => {
  const { setIsLoading } = useLoading();
  const { searchQuery, setSearchResults } = useSearch();
  const [error, setError] = useState(null);
  const [notifications, setNotifications] = useState({ data: [], currentPage: 1, totalPages: 1 });
  const [registeredNotification, setResgisterNotification] = useState(null);
  const toast = useToast({ position: 'top' });

  const fetchNotification = useCallback(
    async (page = 1, query = searchQuery) => {
      setIsLoading(true);
      setError(null);

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}api/v1/admin/notification/fetch/all`,
          {
            params: { page, query },
            validateStatus: (status) => status >= 200 && status < 500,
          }
        );

        if (data?.success) {
          const result = {
            data: data.data,
            totalPages: data.pagination?.totalPages || 1,
            currentPage: page,
          };
          setNotifications(result);

          // ✅ Store search results separately if query exists
          if (query) {
            setSearchResults(data.data);
          }
        } else {
          setError(data?.message || 'Failed to fetch notifications.');
        }
      } catch (err) {
        setError(err.message || 'An error occurred while fetching notifications.');
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setNotifications, setError, searchQuery, setSearchResults]
  );


  const registerNotification = async (formData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/notification/create`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        const newNotification = response.data.data;
        setResgisterNotification(newNotification);

        toast({
          title: 'Success',
          description:
            response.data.message || 'New notification added successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        setError(response.data.message || 'Failed to add new notification');
        toast({
          title: 'Error',
          description:
            response.data.message || 'Failed to add new notification',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      setError(
        'An error occurred while adding the notification. Please try again.',
      );
      toast({
        title: 'Error',
        description:
          'An error occurred while adding the notification. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    registeredNotification,
    registerNotification,
    fetchNotification,
    notifications,
    error,
  };
};

export default useNotification;
