// import React, { createContext, useCallback, useContext, useState } from 'react';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// import { useAuth } from './AuthContext';

// const searchAPIs = {
//   '/admin/admin-table': `${process.env.REACT_APP_BASE_URL}api/v1/admin/search`, // We'll dynamically add the adminId
//   '/admin/customer': `${process.env.REACT_APP_BASE_URL}api/v1/admin/customer/search`,
//   '/admin/partner': `${process.env.REACT_APP_BASE_URL}api/v1/admin/partner/search`,
//   '/admin/stock': `${process.env.REACT_APP_BASE_URL}api/v1/admin/stock/search`,

//   '/admin/category': `${process.env.REACT_APP_BASE_URL}api/v1/admin/category/search`,
//   '/admin/sub-category': `${process.env.REACT_APP_BASE_URL}api/v1/admin/subcategory/search`,

//   // '/admin/banner': `${process.env.REACT_APP_BASE_URL}api/v1/admin/banner`,
//   '/admin/products': `${process.env.REACT_APP_BASE_URL}api/v1/admin/product/search`,
//   // '/admin/membership': `${process.env.REACT_APP_BASE_URL}api/v1/admin/membership/search-membership`,
//   // '/admin/feedback': `${process.env.REACT_APP_BASE_URL}api/v1/admin/feedback/search-feedback`,
//   // '/admin/complaints': `${process.env.REACT_APP_BASE_URL}api/v1/admin/complain/search-complaint`,
//   // '/admin/notification': `${process.env.REACT_APP_BASE_URL}api/v1/admin/notification/search-notification`,
//   '/admin/offers': `${process.env.REACT_APP_BASE_URL}api/v1/admin/offer/search`,
//   // '/admin/slots': `${process.env.REACT_APP_BASE_URL}api/v1/admin/slot/search-slot`,
//   '/admin/booking': `${process.env.REACT_APP_BASE_URL}api/v1/admin/stock/booking/search`,
// };

// const SearchContext = createContext();

// export const SearchProvider = ({ children }) => {
//   const [searchQuery, setSearchQuery] = useState('');
//   const [searchResults, setSearchResults] = useState([]);
//   const location = useLocation();
//   const { token, user } = useAuth(); // Move this inside the SearchProvider

//   // Memoized handleSearch function
//   const handleSearch = useCallback(
//     async (page = 1) => {
//       const currentPath = location.pathname;
//       let searchAPI = searchAPIs[currentPath];

//       if (searchAPI) {
//         try {
//           // For /admin/admin-table, append user.id dynamically
//           if (currentPath === '/admin/admin-table') {
//             searchAPI = `${searchAPI}?adminId=${user.id}`; // Add user.id dynamically
//           }

//           const response = await axios.get(searchAPI, {
//             params: { query: searchQuery, page },
//             headers: {
//               'Content-Type': 'application/json',
//               Authorization: `${token}`,
//             },
//             validateStatus: function (status) {
//               return status >= 200 && status < 500;
//             },
//           });

//           if (response.data.success) {
//             if (response.data.data.length > 0) {
//               setSearchResults({
//                 data: response.data.data,
//                 totalPages: response.data.totalPages,
//                 currentPage: page,
//               }); // Set search results when data is found
//             } else if (response.data.length) {
//               setSearchResults(response.data); // Set a message when no data is found
//             }
//           } else {
//             console.error('Error in response:', response.data.message);
//             setSearchResults([{ message: response.data.message }]); // Use the message from response
//           }

//           // console.log(response.data);
//         } catch (error) {
//           console.error('Error searching data:', error);
//           setSearchResults([{ message: 'An error occurred while searching.' }]); // Handle error case
//         }
//       } else {
//         console.error('No search API mapped for this route');
//       }
//     },
//     [searchQuery, location.pathname, user, token],
//   ); // Add user as a dependency

//   // Memoized clearSearchResults function
//   const clearSearchResults = useCallback(() => {
//     setSearchResults([]);
//   }, []);

//   return (
//     <SearchContext.Provider
//       value={{
//         searchQuery,
//         setSearchQuery,
//         searchResults,
//         handleSearch,
//         clearSearchResults,
//       }}
//     >
//       {children}
//     </SearchContext.Provider>
//   );
// };

// // Create a custom hook to use the SearchContext
// export const useSearch = () => {
//   return useContext(SearchContext);
// };
import React, { createContext, useContext, useState } from "react";

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]); // ✅ Add searchResults
  const clearSearchResults = () => setSearchResults([]); // ✅ Clear function
  

  return (
    <SearchContext.Provider value={{ searchQuery, setSearchQuery, searchResults, setSearchResults, clearSearchResults }}>
      {children}
    </SearchContext.Provider>
  );
};

// Custom hook for using search context
export const useSearch = () => useContext(SearchContext);
