// import { useState, useCallback } from 'react';
// import axios from 'axios';
// import { useLoading } from 'contexts/LoadingContext';

// const useCustomer = () => {
//   const [customers, setCustomers] = useState([]);
//   const { setIsLoading } = useLoading();
//   const [error, setError] = useState(null);

//   const fetchCustomers = useCallback(async (page = 1) => {
//     setIsLoading(true);
//     setError(null);
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}api/v1/admin/customer/all?page=${page}`,
//         {
//           validateStatus: function (status) {
//             return status >= 200 && status < 500;
//           },
//         },
//       );
//       // console.log(response);

//       if (response.data.success) {
//         setCustomers({
//           data: response.data.data,
//           totalPages: response.data.totalPages,
//           currentPage: page,
//         });
//       } else {
//         setError(response.data.message || 'Failed to fetch customers');
//       }
//     } catch (err) {
//       setError(err.message);
//     } finally {
//       setIsLoading(false);
//     }
//   }, []);

//   return {
//     fetchCustomers,
//     customers,
//     error,
//   };
// };

// export default useCustomer;
import { useState, useCallback } from 'react';
import axios from 'axios';
import { useLoading } from 'contexts/LoadingContext';
import { useSearch } from 'contexts/SearchContext';

const useCustomer = () => {
  const [customers, setCustomers] = useState({ data: [], currentPage: 1, totalPages: 1 });
  const [error, setError] = useState(null);
  const { setIsLoading } = useLoading();
  const { searchQuery, setSearchResults } = useSearch();

  const fetchCustomers = useCallback(
    async (page = 1, query = searchQuery) => {
      setIsLoading(true);
      setError(null);

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}api/v1/admin/customer/all`,
          {
            params: { page, query },
            validateStatus: (status) => status >= 200 && status < 500,
          }
        );

        if (data?.success) {
          const result = {
            data: data.data,
            totalPages: data.pagination?.totalPages || 1,
            currentPage: page,
          };
          setCustomers(result);

          // ✅ Store search results separately if query exists
          if (query) {
            setSearchResults(data.data);
          }
        } else {
          setError(data?.message || "Failed to fetch customers.");
        }
      } catch (err) {
        setError(err.message || "An error occurred while fetching customers.");
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setCustomers, setError, searchQuery, setSearchResults]
  );

  return {
    fetchCustomers,
    customers,
    error,
  };
};

export default useCustomer;
