import { useState } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';

export const useUpdateAdminPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast({ position: 'top' });

  const updateAdminPassword = async (username, oldPassword, password) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/update/password`,
        { username, oldPassword, password }, // Added oldPassword
        {
          validateStatus: (status) => status >= 200 && status < 500,
        }
      );

      if (response.data.success) {
        toast({
          title: 'Password Updated Successfully',
          description:
            response.data.message ||
            'The admin password has been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          title: 'Update Failed',
          description:
            response.data.message ||
            'Failed to update password. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      console.error('Error updating password:', error);
      toast({
        title: 'Error',
        description:
          'An error occurred while updating the password. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return { updateAdminPassword, isSubmitting };
};
