import { useState, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from 'contexts/AuthContext';
import { useToast } from '@chakra-ui/react';
import { useLoading } from 'contexts/LoadingContext';

const useComplain = () => {
  const [error, setError] = useState(null);
  const [complain, setComplain] = useState([]);

  const { isLoading,setIsLoading } = useLoading();
  const toast = useToast({ position: 'top' });
  const { token } = useAuth();

  const fetchComplain = useCallback(async (page = 1) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/complain/all?page=${page}`,
        {
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );
      // console.log(response);

      if (response.data.success) {
        setComplain({
          data: response.data.data,
          totalPages: response.data.totalPages,
          currentPage: page,
        });
      } else {
        setError(response.data.message || 'Failed to fetch complaints.');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const resolveComplain = async (complainId, updatedData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/complain/resolved/?complaintId=${complainId}`,
        updatedData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        toast({
          title: 'Complaint Updated',
          description: 'Complaint details have been updated successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          title: 'Update Failed',
          description:
            response.data.message || 'Failed to update complaint details.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      setError(
        'An error occurred while resolving the complaint. Please try again.',
      );
      toast({
        title: 'Error',
        description:
          'An error occurred while resolving the complaint. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchComplain, resolveComplain, complain, error,isLoading  };
};

export default useComplain;
