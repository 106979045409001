'use client';
/* eslint-disable */

import {
  Flex,
  Spinner,
  Text,
  useColorModeValue,
  useToast,useDisclosure,Menu, MenuButton, MenuList, MenuItem, IconButton,AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter,Button
} from '@chakra-ui/react';
import { useRef } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import Card from 'components/card/Card';
import useStock from 'hooks/stockApis/useStock';
import usePagination from 'hooks/usePagination';
import StocksPopup from 'components/popups/stocksPopup/StocksPopup';
import useStockActions from 'hooks/stockApis/useStockActions';
import ReusableTable from 'components/reusableTable/ReusableTable';
import Pagination from '../../../../components/pagination/Pagination';
import DeleteButton from 'components/popups/deleteButtonPopup/DeleteButton';
import GenericDetailView from 'components/popups/GenericDetailView/GenericDetailView';
import EditStockDetails from 'components/popups/stocksPopup/EditStockDetails';
import { useSearch } from 'contexts/SearchContext';
import EditStockButton from 'components/popups/editButtonPopup/EditStockButton';
import { FiMoreVertical } from 'react-icons/fi';
// Assets

const columnHelper = createColumnHelper();
const ITEMS_PER_PAGE = 10;

export default function ComplexTable({ isOpen, onClose }) {
  const { stocks, error, fetchStocks, registerStock } = useStock();
  const { deleteProduct, updateStockDetails } = useStockActions();
 const { searchQuery, setSearchQuery, searchResults, setSearchResults, clearSearchResults } = useSearch();
    const [localSearch, setLocalSearch] = React.useState(searchQuery);
  // console.log(searchResults);

  const totalPages = searchQuery
    ? searchResults.totalPages || 1
    : stocks.totalPages || 1;
  const toast = useToast({ position: 'top' });
  const { currentPage } = usePagination(totalPages);

  React.useEffect(() => {
    fetchStocks(1, searchQuery);
  }, [searchQuery]);
  const handlePageChange = (newPage) => {
    fetchStocks(newPage, searchQuery);
  };

  const handleAddNewStock = async (formData) => {
    try {
      await registerStock(formData);
      await fetchStocks(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to delete product.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditStock = async (stockId, userData) => {
    try {
      await updateStockDetails(stockId, userData);
      await fetchStocks(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update stock details.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteProduct(id);
      await fetchStocks(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to delete product.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const data = React.useMemo(() => {
    if (searchQuery && searchResults.data?.length > 0) {
      return searchResults.data;
    }
    return stocks.data || [];
  }, [stocks.data, searchResults.data, searchQuery]);

  const columns = [
    columnHelper.accessor('sl. no.', {
      id: 'sl. no.',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SL. NO.
        </Text>
      ),
      cell: ({ row }) => {
        const serialNumber = (currentPage - 1) * ITEMS_PER_PAGE + row.index + 1;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700" width="35px">
              {serialNumber}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('image', {
      id: 'image',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          IMAGE
        </Text>
      ),
      cell: ({ row }) => {
        const images = row.original?.image || [];

        return (
          <GenericDetailView
            type="image"
            content={images}
            title="Image Preview"
            imageAlt="Stock Image"
          />
        );
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          NAME
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            whiteSpace="nowrap"
          >
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('brand', {
      id: 'brand',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          BRAND
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            whiteSpace="nowrap"
          >
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('size', {
      id: 'size',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SIZE
        </Text>
      ),
      cell: (info) => {
        return (
          <Flex align="center" color={textColor}>
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              whiteSpace="nowrap"
            >
              {info.getValue() || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),
     columnHelper.accessor('position', {
          id: 'position',
          header: () => (
            <Text
              justifyContent="space-between"
              align="center"
              fontSize={{ sm: '11px', lg: '13px' }}
              color={textColor}
            >
              POSITION
            </Text>
          ),
          cell: (info) => (
            <Flex align="center">
              <Text color={textColor} fontSize="sm" fontWeight="700">
                {info.getValue() || 'NA'}
              </Text>
            </Flex>
          ),
        }),
    columnHelper.accessor('currentStock', {
      id: 'currentStock',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
          whiteSpace="nowrap"
        >
          CUR. STOCK
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('mrp', {
      id: 'mrp',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          MRP
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            whiteSpace="nowrap"
          >
            {info.getValue() === null || info.getValue() === undefined
              ? 'NA'
              : `₹ ${info.getValue()}`}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('purchasingRate', {
      id: 'purchasingRate',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
          whiteSpace="nowrap"
        >
          PURC. RATE
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() === null || info.getValue() === undefined
              ? 'NA'
              : `₹ ${info.getValue()}`}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('barcodeNumber', {
      id: 'barcodeNumber',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          BARCODE NO.
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ACTION
        </Text>
      ),
     cell: ({ row }) => {
                  const { isOpen, onOpen, onClose } = useDisclosure();
                  const cancelRef = useRef();
                  return (
        <Flex align="center">
          <EditStockButton
            entityType="stock"
            EditComponent={EditStockDetails}
            initialData={row.original}
            onConfirm={(updatedData) =>
              handleEditStock(row.original._id, updatedData)
            }
          />
          <Menu strategy="fixed" closeOnSelect={false}>
                                 <MenuButton
                                   as={IconButton}
                                   icon={<FiMoreVertical />}
                                   variant="ghost"
                                   aria-label="Options"
                                 />
                                 <MenuList>
                                   {!row.original.isDeleted && (
                                     <MenuItem color="red.500" onClick={onOpen}>
                                       Delete
                                     </MenuItem>
                                   )}
                                 </MenuList>
                               </Menu>
                         
                               {/* Confirmation Dialog for Deletion */}
                               <AlertDialog
                                 isOpen={isOpen}
                                 leastDestructiveRef={cancelRef}
                                 onClose={onClose}
                               >
                                 <AlertDialogOverlay>
                                   <AlertDialogContent>
                                     <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                       Confirm Delete
                                     </AlertDialogHeader>
                         
                                     <AlertDialogBody>
                                       Are you sure you want to delete this Stock?
                                     </AlertDialogBody>
                         
                                     <AlertDialogFooter>
                                       <Button ref={cancelRef} onClick={onClose}>
                                         Cancel
                                       </Button>
                                       <Button
                                         colorScheme="red"
                                         onClick={() => {
                                           handleDelete(row.original._id);
                                           onClose();
                                         }}
                                         ml={3}
                                       >
                                         Delete
                                       </Button>
                                     </AlertDialogFooter>
                                   </AlertDialogContent>
                                 </AlertDialogOverlay>
                               </AlertDialog>
                             </Flex>
      );
    },
  }),
];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error)
    return toast({
      title: 'Error',
      description: stocks.message || 'Failed to fetch products',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p="0px"
        maxH="60vh"
        minH="auto"
        overflowX="auto"
        overflowY="auto"
      >
        <ReusableTable
          table={table}
          data={data}
          borderColor="gray.200"
          noDataText="No records found."
          bgColor="aliceblue"
          isRowDeleted={(row) => row.original.isDeleted}
          rowBgColor="gray.200"
          rowTextColor="gray.600"
        />
        <StocksPopup
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleAddNewStock}
        />
      </Card>
      {data.length === 0 ? (
        <></>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
