import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
  Image,
  Select as ChakraSelect,
  Text,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { format } from 'date-fns';
import { handleImageUpload } from 'utils/handleImageUpload';

const CreateNotificationPopup = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  customers = [],
  partners = [],
}) => {
  const [formData, setFormData] = useState({
    title: '',
    body: '',
    image: '',
    targetAudience: [],
    notificationDate: '',
    notificationTime: '',
    audienceType: '',
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (value.trim() === '') {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    setFormData((prev) => ({ ...prev, notificationDate: formattedDate }));
  };

  const handleTimeChange = (e) => {
    const time = e.target.value; // Get time from the input in 24-hour format (HH:mm)

    const selectedDate = formData.notificationDate
      ? new Date(formData.notificationDate)
      : new Date();

    // Combine the selected date and time
    const selectedTime = new Date(`${selectedDate.toDateString()} ${time}`);

    // Format time to "hh:mm A" (e.g., "05:00 PM")
    const formattedTime = format(selectedTime, 'hh:mm a').toUpperCase();

    // Check if the selected time is in the future
    if (selectedTime <= new Date()) {
      setErrors((prev) => ({
        ...prev,
        notificationTime: 'Time must be in the future',
      }));
    } else {
      setErrors((prev) => ({ ...prev, notificationTime: '' }));
      setFormData((prev) => ({
        ...prev,
        notificationTime: formattedTime, // Save formatted time in "hh:mm A"
      }));
    }
  };

  // Helper function to convert "hh:mm A" back to "HH:mm" for the input value
  const convertTo24Hour = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };

  const handleAudienceSelect = (selectedOptions) => {
    // Check if 'select-all' option is selected
    const selectAllOption = selectedOptions.find(
      (option) => option.value === 'select-all',
    );

    if (selectAllOption) {
      // If 'select-all' is selected, set targetAudience based on audienceType
      if (formData.audienceType === 'customer') {
        // Set targetAudience to all customer IDs
        setFormData((prev) => ({
          ...prev,
          targetAudience: customers.map((customer) => customer._id),
        }));
      } else if (formData.audienceType === 'partner') {
        // Set targetAudience to all partner IDs
        setFormData((prev) => ({
          ...prev,
          targetAudience: partners.map((partner) => partner._id),
        }));
      }
    } else {
      // Set targetAudience to selected options' values (or empty array if none)
      setFormData((prev) => ({
        ...prev,
        targetAudience: selectedOptions
          ? selectedOptions.map((option) => option.value)
          : [],
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.title.trim()) newErrors.title = 'Title is required';
    if (!formData.body.trim()) newErrors.body = 'Body is required';
    if (!formData.audienceType)
      newErrors.audienceType = 'Audience type is required';
    if (formData.targetAudience.length === 0) {
      newErrors.targetAudience = 'Select at least one audience';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const finalFormData = new FormData();

      // Append all formData key-value pairs to FormData object
      for (const key in formData) {
        if (key === 'targetAudience') {
          // Handle array separately, appending each value
          formData[key].forEach((id) => {
            finalFormData.append('targetAudience[]', id);
          });
        } else {
          finalFormData.append(key, formData[key]);
        }
      }

      try {
        await onConfirm(finalFormData);
        onClose();
      } catch (error) {
        console.error('Error creating notification:', error);
      }
    }
  };

  const audienceOptions =
    formData.audienceType === 'customer'
      ? [
          { value: 'select-all', label: 'Select All Customers' },
          ...customers.map((customer) => ({
            value: customer._id,
            label: customer.name,
          })),
        ]
      : formData.audienceType === 'partner'
      ? [
          { value: 'select-all', label: 'Select All Partners' },
          ...partners.map((partner) => ({
            value: partner._id,
            label: partner.name,
          })),
        ]
      : [];
      const isFormValid =
      formData.title.trim() &&
      formData.body.trim() &&
      formData.audienceType &&
      formData.targetAudience.length > 0 &&
      formData.notificationDate &&
      formData.notificationTime;
    
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Notification</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={!!errors.title} mb={3}>
            <FormLabel>Title</FormLabel>
            <Input
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              placeholder="Enter title"
            />
            <FormErrorMessage>{errors.title}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.body} mb={3}>
            <FormLabel>Body</FormLabel>
            <Textarea
              name="body"
              value={formData.body}
              onChange={handleInputChange}
              placeholder="Enter body"
            />
            <FormErrorMessage>{errors.body}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.audienceType} mb={3}>
            <FormLabel>Audience Type</FormLabel>
            <ChakraSelect
              name="audienceType"
              placeholder="Select Audience Type"
              value={formData.audienceType}
              onChange={(e) => handleInputChange(e)}
            >
              <option value="customer">Customer</option>
              <option value="partner">Partner</option>
            </ChakraSelect>
            <FormErrorMessage>{errors.audienceType}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.targetAudience} mb={3}>
            <FormLabel>Select Audience</FormLabel>
            <Select
              isMulti
              options={audienceOptions}
              onChange={handleAudienceSelect}
              value={audienceOptions.filter((option) =>
                formData.targetAudience.includes(option.value),
              )}
              placeholder="Select audience"
              isDisabled={!formData.audienceType} // Disable until audience type is selected
            />
            <FormErrorMessage>{errors.targetAudience}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.notificationDate} mb={3}>
            <FormLabel>Date</FormLabel>
            <DatePicker
              selected={
                formData.notificationDate
                  ? new Date(formData.notificationDate)
                  : new Date() // Show current date as default
              }
              onChange={handleDateChange}
              minDate={new Date()} // Ensure no past date is selectable
              dateFormat="yyyy-MM-dd"
              placeholderText={
                formData.notificationDate ? undefined : 'yyyy-MM-dd'
              } // Placeholder for the current date format
            />
            <FormErrorMessage>{errors.notificationDate}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.notificationTime} mb={3}>
            <FormLabel>Time</FormLabel>
            <Input
              name="notificationTime"
              type="time"
              onChange={handleTimeChange}
              value={
                formData.notificationTime
                  ? convertTo24Hour(formData.notificationTime)
                  : ''
              }
              placeholder="Select time"
            />
            <FormErrorMessage>{errors.notificationTime}</FormErrorMessage>
          </FormControl>

          {/* Time Preview */}
          {formData.notificationTime && (
            <Text color="blue.500" fontSize="md">
              Selected Time: {formData.notificationTime}
            </Text>
          )}

          <FormControl isInvalid={!!errors.image} mb={3}>
            <FormLabel>Upload Image</FormLabel>
            <Input
              name="image"
              type="file"
              accept="image/*"
              onChange={(e) =>
                handleImageUpload(e, setErrors, setFormData, setImagePreview)
              }
            />
            <FormErrorMessage>{errors.image}</FormErrorMessage>
          </FormControl>
          {imagePreview && (
            <Image src={imagePreview} alt="Preview" maxWidth="100px" mt={3} />
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            isLoading={isLoading}
            colorScheme="blue"
          >
            Create
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateNotificationPopup;
