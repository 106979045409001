'use client';
/* eslint-disable */

import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Spinner,useDisclosure,Menu, MenuButton, MenuList, MenuItem, IconButton,AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter,Button
} from '@chakra-ui/react';
import { useRef } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import Card from 'components/card/Card';
import usePagination from 'hooks/usePagination';
import useCategory from 'hooks/categoryApis/useCategory';
import ReusableTable from 'components/reusableTable/ReusableTable';
import Pagination from '../../../../components/pagination/Pagination';
import EditButton from 'components/popups/editButtonPopup/EditButton';
import useCategoryActions from 'hooks/categoryApis/useCategoryActions';
import CategoryPopup from 'components/popups/categoryPopup/CategoryPopup';
import DeleteButton from 'components/popups/deleteButtonPopup/DeleteButton';
import SwitchToggler from 'components/popups/updateStatusPopup/SwitchToggler';
import EditCategoryDetails from 'components/popups/categoryPopup/EditCategoryDetails';
import GenericDetailView from 'components/popups/GenericDetailView/GenericDetailView';
import { useSearch } from 'contexts/SearchContext';
import { FiMoreVertical } from 'react-icons/fi';
// Assets

const columnHelper = createColumnHelper();
const ITEMS_PER_PAGE = 10;

export default function ComplexTable({ isOpen, onClose }) {
  const toast = useToast({ position: 'top' });
  const { category, error, fetchCategory, registerCategory } = useCategory();
  const { deleteCategory, updateCategoryDetails } = useCategoryActions();
const { searchQuery, setSearchQuery, searchResults, setSearchResults, clearSearchResults } = useSearch();
  const [localSearch, setLocalSearch] = React.useState(searchQuery);
  // console.log(searchResults);

  const totalPages = searchQuery
    ? searchResults.totalPages || 1
    : category.totalPages || 1;
  const { currentPage } = usePagination(totalPages);

React.useEffect(() => {
  fetchCategory(1, searchQuery);
}, [searchQuery]);
const handlePageChange = (newPage) => {
  fetchCategory(newPage, searchQuery);
};


  const handleAddCategory = async (formData) => {
    try {
      await registerCategory(formData);
      await fetchCategory(currentPage);
    } catch (error) {
      toast({
        title: 'Adding Failed',
        description: error.message || 'Failed to add category.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditCategory = async (categoryId, userData) => {
    try {
      await updateCategoryDetails(categoryId, userData);
      await fetchCategory(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteCategory(id);
      await fetchCategory(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to delete category.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const data = React.useMemo(() => {
    if (searchQuery && searchResults.data?.length > 0) {
      return searchResults.data;
    }
    return category.data || [];
  }, [category.data, searchResults.data, searchQuery]);

  const columns = [
    columnHelper.accessor('sl. no.', {
      id: 'sl. no.',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SL. NO.
        </Text>
      ),
      cell: ({ row }) => {
        const serialNumber = (currentPage - 1) * ITEMS_PER_PAGE + row.index + 1;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {serialNumber}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('image', {
      id: 'image',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          IMAGE
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <GenericDetailView
            type="image"
            content={info.getValue()}
            title="Image Preview"
            imageAlt="Category Image"
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          NAME
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            whiteSpace="nowrap"
          >
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('position', {
      id: 'position',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          POSITION
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('isActive', {
      id: 'isActive',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          STATUS
        </Text>
      ),
      cell: ({ row }) => (
        <Flex align="center">
          <SwitchToggler
            entityType="category"
            entityId={row.original._id}
            initialStatus={row.original.isActive}
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ACTION
        </Text>
      ),
       cell: ({ row }) => {
                   const { isOpen, onOpen, onClose } = useDisclosure();
                   const cancelRef = useRef();
        const initialData = row.original;
        return (
          <Flex align="center">
            <EditButton
              entityType="category"
              EditComponent={EditCategoryDetails}
              initialData={initialData}
              onConfirm={(updatedData) =>
                handleEditCategory(initialData._id, updatedData)
              }
            />
             <Menu strategy="fixed" closeOnSelect={false}>
                                    <MenuButton
                                      as={IconButton}
                                      icon={<FiMoreVertical />}
                                      variant="ghost"
                                      aria-label="Options"
                                    />
                                    <MenuList>
                                      {!row.original.isDeleted && (
                                        <MenuItem color="red.500" onClick={onOpen}>
                                          Delete
                                        </MenuItem>
                                      )}
                                    </MenuList>
                                  </Menu>
                            
                                  {/* Confirmation Dialog for Deletion */}
                                  <AlertDialog
                                    isOpen={isOpen}
                                    leastDestructiveRef={cancelRef}
                                    onClose={onClose}
                                  >
                                    <AlertDialogOverlay>
                                      <AlertDialogContent>
                                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                          Confirm Delete
                                        </AlertDialogHeader>
                            
                                        <AlertDialogBody>
                                          Are you sure you want to delete this Category?
                                        </AlertDialogBody>
                            
                                        <AlertDialogFooter>
                                          <Button ref={cancelRef} onClick={onClose}>
                                            Cancel
                                          </Button>
                                          <Button
                                            colorScheme="red"
                                            onClick={() => {
                                              handleDelete(row.original._id);
                                              onClose();
                                            }}
                                            ml={3}
                                          >
                                            Delete
                                          </Button>
                                        </AlertDialogFooter>
                                      </AlertDialogContent>
                                    </AlertDialogOverlay>
                                  </AlertDialog>
                                </Flex>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error)
    return toast({
      title: 'Error',
      description: category.message || 'Failed to fetch categories.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p="0px"
        maxH="60vh"
        minH="auto"
        overflowX="auto"
        overflowY="auto"
      >
        <ReusableTable
          table={table}
          data={data}
          borderColor="gray.200"
          noDataText="No records found."
          bgColor="aliceblue"
          isRowDeleted={(row) => row.original.isDeleted}
          rowBgColor="gray.200"
          rowTextColor="gray.600"
        />
        <CategoryPopup
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleAddCategory}
        />
      </Card>
      {data.length === 0 ? (
        <></>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
