import { useState, useCallback } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useLoading } from 'contexts/LoadingContext';
import { useSearch } from 'contexts/SearchContext';

const useBooking = () => {
  const { setIsLoading } = useLoading();
  const { searchQuery, setSearchResults } = useSearch();
  const [error, setError] = useState(null);
  const [booking, setBooking] = useState({ data: [], currentPage: 1, totalPages: 1 });
  const toast = useToast({ position: 'top' });

  const fetchBooking = useCallback(
    async (page = 1, query = searchQuery) => {
      setIsLoading(true);
      setError(null);

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}api/v1/admin/booking/fetch/all`,
          {
            params: { page, query },
            validateStatus: (status) => status >= 200 && status < 500,
          }
        );

        if (data?.success) {
          const result = {
            data: data.data,
            totalPages: data.pagination?.totalPages || 1,
            currentPage: page,
          };
          setBooking(result);

          // ✅ Store search results separately if query exists
          if (query) {
            setSearchResults(data.data);
          }
        } else {
          setError(data?.message || 'Failed to fetch bookings.');
        }
      } catch (err) {
        setError(err.message || 'An error occurred while fetching bookings.');
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setBooking, setError, searchQuery, setSearchResults]
  );

  const downloadExcel = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/booking/download-excel`,
        {
          responseType: 'blob',
        },
      );

      // Step 2: Create a URL for the blob (the Excel file)
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Step 3: Create a link to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'bookings.xlsx'); // File name
      document.body.appendChild(link);
      link.click(); // Trigger the download
      link.parentNode.removeChild(link); // Clean up the DOM

      // Optionally, release the object URL after use
      window.URL.revokeObjectURL(url);

      // Success toast
      toast({
        title: 'Download successful!',
        description: 'Booking data has been downloaded.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      // Error toast
      toast({
        title: 'Download failed!',
        description: 'Something went wrong while downloading the Booking data.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const assignPartner = async (partnerId, bookingId) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/booking/assign-partner/?partnerId=${partnerId}&bookingId=${bookingId}`,
        {
          validateStatus: (status) => status >= 200 && status < 500,
        },
      );

      if (response.data.success) {
        toast({
          title: 'Success',
          description: 'Booking assigned successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        toast({
          title: 'Error',
          description: response.data.message || 'Failed to assign partner.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      toast({
        title: 'Assign Failed',
        description: error.message || 'Failed to assign partner.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchBooking,
    downloadExcel,
    assignPartner,
    booking,
    error,
  };
};

export default useBooking;
