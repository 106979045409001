'use client';
/* eslint-disable */

import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import Card from 'components/card/Card';
import usePagination from 'hooks/usePagination';
import convertDateFormat from 'utils/extractDate';
import ReusableTable from 'components/reusableTable/ReusableTable';
import Pagination from '../../../../components/pagination/Pagination';
import GenericDetailView from 'components/popups/GenericDetailView/GenericDetailView';
import { useSearch } from 'contexts/SearchContext';
import useStockBooking from 'hooks/bookingApis/useStockBooking';
// Assets

const columnHelper = createColumnHelper();
const ITEMS_PER_PAGE = 10;

export default function ComplexTable() {
  const toast = useToast({ position: 'top' });
  const { booking, error, fetchBooking } = useStockBooking();
   const { searchQuery, setSearchQuery, searchResults, setSearchResults, clearSearchResults } = useSearch();
   const [localSearch, setLocalSearch] = React.useState(searchQuery);
  // console.log(booking);

  const totalPages = searchQuery
    ? searchResults.totalPages || 1
    : booking.totalPages || 1;
  const { currentPage } = usePagination(totalPages);

 
  React.useEffect(() => {
    fetchBooking(1, searchQuery);
  }, [searchQuery]);
  const handlePageChange = (newPage) => {
    fetchBooking(newPage, searchQuery);
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const data = React.useMemo(() => {
    if (searchQuery && searchResults.data?.length > 0) {
      return searchResults.data;
    }
    return booking.data || [];
  }, [booking.data, searchQuery, searchResults.data]);

  const columns = [
    columnHelper.accessor('sl. no.', {
      id: 'sl. no.',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SL. NO.
        </Text>
      ),
      cell: ({ row }) => {
        const serialNumber = (currentPage - 1) * ITEMS_PER_PAGE + row.index + 1;
        return (
          <Flex align="center">
            <Text width="35px" color={textColor} fontSize="sm" fontWeight="700">
              {serialNumber}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          NAME
        </Text>
      ),
      cell: ({ row }) => {
        const partnerName = row.original.partner?.name;

        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              whiteSpace="nowrap"
            >
              {partnerName || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('number', {
      id: 'number',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          NUMBER
        </Text>
      ),
      cell: ({ row }) => {
        const number = row.original.partner?.phone;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {number || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('email', {
      id: 'email',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          EMAIL
        </Text>
      ),
      cell: ({ row }) => {
        const email = row.original.partner?.email;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {email || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('_id', {
      id: '_id',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          BOOKING ID
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          BOOKED ON
        </Text>
      ),
      cell: (info) => {
        const formattedDate = convertDateFormat(info.getValue());
        return (
          <Flex align="center">
            <Text width="90px" color={textColor} fontSize="sm" fontWeight="700">
              {formattedDate || 'NA'}
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('productName', {
      id: 'productName',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          PRODUCT NAME
        </Text>
      ),
      cell: ({ row }) => {
        const products = row.original.product || [];

        const productNames = products
          .map((product) => product.product?.name || 'NA')
          .join(', ');

        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight="700"
              width="120px"
            >
              <GenericDetailView
                type="text"
                content={productNames || 'NA'}
                title="Brand Name"
                maxChars={15}
                truncateType="chars"
              />
            </Text>
          </Flex>
        );
      },
    }),

    columnHelper.accessor('totalPrice', {
      id: 'totalPrice',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          TOTAL PRICE
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text width="90px" color={textColor} fontSize="sm" fontWeight="700">
            ₹ {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          STATUS
        </Text>
      ),
      cell: (info) => {
        const statusValue = info.getValue() || 'NA';

        let bgColor;
        switch (statusValue.toLowerCase()) {
          case 'delivered':
            bgColor = 'green.300';
            break;
          case 'cancelled':
            bgColor = 'red.300';
            break;
          case 'refunded':
            bgColor = 'yellow.300';
            break;
          default:
            bgColor = 'gray.300';
        }

        return (
          <Flex align="center" bg={bgColor} p={2} borderRadius="md">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {statusValue}
            </Text>
          </Flex>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error)
    return toast({
      title: 'Error',
      description: booking.message || 'Failed to fetch booking.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p="0px"
        maxH="60vh"
        minH="auto"
        overflowX="auto"
        overflowY="auto"
      >
        <ReusableTable
          table={table}
          data={data}
          borderColor="gray.200"
          noDataText="No records found."
          bgColor="aliceblue"
          isRowDeleted={(row) => row.original.isDeleted}
          rowBgColor="gray.200"
          rowTextColor="gray.600"
        />
      </Card>
      {data.length === 0 ? (
        <></>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
