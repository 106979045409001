'use client';
/* eslint-disable */

import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Spinner,useDisclosure,Menu, MenuButton, MenuList, MenuItem, IconButton,AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter,Button
} from '@chakra-ui/react';
import { useRef } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import Card from 'components/card/Card';
import usePagination from 'hooks/usePagination';
import ReusableTable from 'components/reusableTable/ReusableTable';
import Pagination from '../../../../components/pagination/Pagination';
import EditButton from 'components/popups/editButtonPopup/EditButton';
import useMembershipPlan from 'hooks/membershipApis/useMembershipPlan';
import DeleteButton from 'components/popups/deleteButtonPopup/DeleteButton';
import SwitchToggler from 'components/popups/updateStatusPopup/SwitchToggler';
import useMembershipPlanActions from 'hooks/membershipApis/useMemberPlanActions';
import MembershipPlanPopup from 'components/popups/membershipPopup/MembershipPlanPopup';
import EditMembershipPlanDetails from 'components/popups/membershipPopup/EditMembershipPlanDetails';
import { useSearch } from 'contexts/SearchContext';
import { FiMoreVertical } from 'react-icons/fi';
// Assets

const columnHelper = createColumnHelper();
const ITEMS_PER_PAGE = 10;

export default function ComplexTable({ isOpen, onClose }) {
  const toast = useToast({ position: 'top' });
  const { membershipPlan, error, fetchMembershipPlan, registerMembershipPlan } =
    useMembershipPlan();

  const { deleteMembershipPlan, updateMembershipPlanDetails } =
    useMembershipPlanActions();
  const { searchResults, searchQuery } = useSearch();
  // console.log(searchResults);

  const totalPages = searchQuery
    ? searchResults.totalPages || 1
    : membershipPlan.totalPages || 1;
  const { currentPage, handlePageChange } = usePagination(totalPages);

  React.useEffect(() => {
    if (!searchQuery) {
      fetchMembershipPlan(currentPage);
    }
  }, [fetchMembershipPlan, currentPage, searchQuery]);

  const handleAddMembershipPlan = async (formData) => {
    try {
      await registerMembershipPlan(formData);
      await fetchMembershipPlan(currentPage);
    } catch (error) {
      toast({
        title: 'Adding Failed',
        description: error.message || 'Failed to add membership plan.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditMembership = async (membershipId, userData) => {
    try {
      await updateMembershipPlanDetails(membershipId, userData);
      await fetchMembershipPlan(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteMembershipPlan(id);
      await fetchMembershipPlan(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to delete membership plan.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const data = React.useMemo(() => {
    if (searchQuery && searchResults.data?.length > 0) {
      return searchResults.data;
    }
    return membershipPlan.data || [];
  }, [membershipPlan.data, searchResults.data, searchQuery]);

  const columns = [
    columnHelper.accessor('sl. no.', {
      id: 'sl. no.',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SL. NO.
        </Text>
      ),
      cell: ({ row }) => {
        const serialNumber = (currentPage - 1) * ITEMS_PER_PAGE + row.index + 1;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {serialNumber}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('tenure', {
      id: 'tenure',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          TENURE
        </Text>
      ),
      cell: ({ row }) => {
        const tenure = row.original.tenure;
        const tenureType = row.original.tenureType;

        return (
          <Flex align="center">
            <Text
              color={textColor}
              fontSize="sm"
              fontWeight={700}
              whiteSpace="nowrap"
            >
              {tenure} {tenureType}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('price', {
      id: 'price',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          PRICE
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            whiteSpace="nowrap"
          >
            {`₹ ${info.getValue()}` || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('discountedPrice', {
      id: 'discountedPrice',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
          whiteSpace="nowrap"
        >
          DISCOUNTED PRICE
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {`₹ ${info.getValue()}` || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('discountPercentage', {
      id: 'discountPercentage',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
          whiteSpace="nowrap"
        >
          DISCOUNT %
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {`${info.getValue()} %` || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('isActive', {
      id: 'isActive',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          STATUS
        </Text>
      ),
      cell: ({ row }) => (
        <Flex align="center">
          <SwitchToggler
            entityType="membership-plan"
            entityId={row.original._id}
            initialStatus={row.original.isActive}
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ACTION
        </Text>
      ),
     cell: ({ row }) => {
                  const { isOpen, onOpen, onClose } = useDisclosure();
                  const cancelRef = useRef();
        const initialData = row.original;
        return (
          <Flex align="center">
            <EditButton
              entityType="membership-plan"
              EditComponent={EditMembershipPlanDetails}
              initialData={initialData}
              onConfirm={(updatedData) =>
                handleEditMembership(initialData._id, updatedData)
              }
            />
             <Menu strategy="fixed" closeOnSelect={false}>
                                    <MenuButton
                                      as={IconButton}
                                      icon={<FiMoreVertical />}
                                      variant="ghost"
                                      aria-label="Options"
                                    />
                                    <MenuList>
                                      {!row.original.isDeleted && (
                                        <MenuItem color="red.500" onClick={onOpen}>
                                          Delete
                                        </MenuItem>
                                      )}
                                    </MenuList>
                                  </Menu>
                            
                                  {/* Confirmation Dialog for Deletion */}
                                  <AlertDialog
                                    isOpen={isOpen}
                                    leastDestructiveRef={cancelRef}
                                    onClose={onClose}
                                  >
                                    <AlertDialogOverlay>
                                      <AlertDialogContent>
                                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                                          Confirm Delete
                                        </AlertDialogHeader>
                            
                                        <AlertDialogBody>
                                          Are you sure you want to delete this Membership?
                                        </AlertDialogBody>
                            
                                        <AlertDialogFooter>
                                          <Button ref={cancelRef} onClick={onClose}>
                                            Cancel
                                          </Button>
                                          <Button
                                            colorScheme="red"
                                            onClick={() => {
                                              handleDelete(row.original._id);
                                              onClose();
                                            }}
                                            ml={3}
                                          >
                                            Delete
                                          </Button>
                                        </AlertDialogFooter>
                                      </AlertDialogContent>
                                    </AlertDialogOverlay>
                                  </AlertDialog>
                                </Flex>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error)
    return toast({
      title: 'Error',
      description:
        membershipPlan.message || 'Failed to fetch membership plans.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p="0px"
        maxH="60vh"
        minH="auto"
        overflowX="auto"
        overflowY="auto"
      >
        <ReusableTable
          table={table}
          data={data}
          borderColor="gray.200"
          noDataText="No records found."
          bgColor="aliceblue"
          isRowDeleted={(row) => row.original.isDeleted}
          rowBgColor="gray.200"
          rowTextColor="gray.600"
        />
        <MembershipPlanPopup
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleAddMembershipPlan}
        />
      </Card>
      {data.length === 0 ? (
        <></>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
