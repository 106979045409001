'use client';
/* eslint-disable */

import {
  Flex,
  Text,
  useColorModeValue,
  useToast,
  Spinner,
  useDisclosure,Menu, MenuButton, MenuList, MenuItem, IconButton,AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter,Button
} from '@chakra-ui/react';
import { useRef } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import Card from 'components/card/Card';
import usePagination from 'hooks/usePagination';
import usePartner from 'hooks/partnerApis/usePartner';
import ReusableTable from 'components/reusableTable/ReusableTable';
import usePartnerActions from 'hooks/partnerApis/usePartnerActions';
import Pagination from '../../../../components/pagination/Pagination';
import EditButton from 'components/popups/editButtonPopup/EditButton';
import PartnerPopup from 'components/popups/partnerPopup/PartnerPopup';
import DeleteButton from 'components/popups/deleteButtonPopup/DeleteButton';
import SwitchToggler from 'components/popups/updateStatusPopup/SwitchToggler';
import EditPartnerDetails from 'components/popups/partnerPopup/EditPartnerDetails';
import GenericDetailView from 'components/popups/GenericDetailView/GenericDetailView';
import { useSearch } from 'contexts/SearchContext';
import { FiMoreVertical } from 'react-icons/fi';
// Assets

const columnHelper = createColumnHelper();
const ITEMS_PER_PAGE = 10;

export default function ComplexTable({ isOpen, onClose }) {
  const toast = useToast({ position: 'top' });
  const { partners, error, fetchPartners, registerPartner } = usePartner();
  const { deletePartner, updatePartnerDetails } = usePartnerActions();
   const { searchQuery, setSearchQuery, searchResults, setSearchResults, clearSearchResults } = useSearch();
   const [localSearch, setLocalSearch] = React.useState(searchQuery);
  // console.log(searchResults);

  const totalPages = searchQuery
    ? searchResults.totalPages || 1
    : partners.totalPages || 1;
  const { currentPage} = usePagination(totalPages);


 React.useEffect(() => {
   fetchPartners(1, searchQuery);
 }, [searchQuery]);
 const handlePageChange = (newPage) => {
   fetchPartners(newPage, searchQuery);
 };

  const handleAddPartner = async (formData) => {
    try {
      await registerPartner(formData);
      await fetchPartners(currentPage);
    } catch (error) {
      toast({
        title: 'Adding Failed',
        description: partners.message || 'Failed to add partner.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditPartner = async (partnerId, userData) => {
    try {
      await updatePartnerDetails(partnerId, userData);
      await fetchPartners(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: partners.message || 'Failed to update status.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deletePartner(id);
      await fetchPartners(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: partners.message || 'Failed to delete partner.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const data = React.useMemo(() => {
    if (searchQuery && searchResults.data?.length > 0) {
      return searchResults.data;
    }
    return partners.data || [];
  }, [partners.data, searchResults.data, searchQuery]);

  const columns = [
    columnHelper.accessor('sl. no.', {
      id: 'sl. no.',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SL. NO.
        </Text>
      ),
      cell: ({ row }) => {
        const serialNumber = (currentPage - 1) * ITEMS_PER_PAGE + row.index + 1;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700" width="35px">
              {serialNumber}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('image', {
      id: 'image',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          Profile
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <GenericDetailView
            type="image"
            content={info.getValue()}
            title="Image Preview"
            imageAlt="Partner Image"
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          NAME
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize="sm"
            fontWeight="700"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          EMAIL
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('phone', {
      id: 'phone',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          Mobile
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('address', {
      id: 'address',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ADDRESS
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text width="170px" color={textColor} fontSize="sm" fontWeight="700">
            <GenericDetailView
              type="text"
              content={info.getValue() || 'NA'}
              title="Address"
              maxChars={20}
              truncateType="chars"
            />
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('pincodes', {
      id: 'pincodes',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          PINCODE
        </Text>
      ),
      cell: ({ row }) => {
        const pincodeString = row.original.pincode || '';
        const pincodesArray = pincodeString.split(',').filter(Boolean);
        const formattedPincodes = pincodesArray.join(', ');

        return (
          <Flex direction="column">
            <Text
              width="150px"
              color={textColor}
              fontSize="sm"
              fontWeight="700"
            >
              <GenericDetailView
                type="text"
                content={formattedPincodes || 'NA'}
                title="Pincodes"
                maxChars={20}
                truncateType="chars"
              />
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          STATUS
        </Text>
      ),
      cell: ({ row }) => (
        <Flex align="center">
          <SwitchToggler
            entityType="partner"
            entityId={row.original._id}
            initialStatus={row.original.isActive}
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ACTION
        </Text>
      ),
       cell: ({ row }) => {
              const { isOpen, onOpen, onClose } = useDisclosure();
              const cancelRef = useRef();
              
        return (
          <Flex align="center">
            <EditButton
              entityType="partner"
              EditComponent={EditPartnerDetails}
              initialData={row.original}
              onConfirm={(updatedData) =>
                handleEditPartner(row.original._id, updatedData)
              }
            />
           <Menu strategy="fixed" closeOnSelect={false}>
                        <MenuButton
                          as={IconButton}
                          icon={<FiMoreVertical />}
                          variant="ghost"
                          aria-label="Options"
                        />
                        <MenuList>
                          {!row.original.isDeleted && (
                            <MenuItem color="red.500" onClick={onOpen}>
                              Delete
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                
                      {/* Confirmation Dialog for Deletion */}
                      <AlertDialog
                        isOpen={isOpen}
                        leastDestructiveRef={cancelRef}
                        onClose={onClose}
                      >
                        <AlertDialogOverlay>
                          <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                              Confirm Delete
                            </AlertDialogHeader>
                
                            <AlertDialogBody>
                              Are you sure you want to delete this Partner?
                            </AlertDialogBody>
                
                            <AlertDialogFooter>
                              <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                              </Button>
                              <Button
                                colorScheme="red"
                                onClick={() => {
                                  handleDelete(row.original._id);
                                  onClose();
                                }}
                                ml={3}
                              >
                                Delete
                              </Button>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialogOverlay>
                      </AlertDialog>
                    </Flex>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error)
    return toast({
      title: 'Error',
      description: partners.message || 'Failed to fetch partners',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p="0px"
        maxH="60vh"
        minH="auto"
        overflowX="auto"
        overflowY="auto"
      >
        <ReusableTable
          table={table}
          data={data}
          borderColor="gray.200"
          noDataText="No records found."
          bgColor="aliceblue"
          isRowDeleted={(row) => row.original.isDeleted}
          rowBgColor="gray.200"
          rowTextColor="gray.600"
        />
        <PartnerPopup
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleAddPartner}
        />
      </Card>
      {data.length === 0 ? (
        <></>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
