import React, { useEffect, useState } from 'react';
import {
  Input,
  FormControl,
  FormLabel,
  Image,
  FormErrorMessage,
  Flex,
  Select,
  Box,
  IconButton,
} from '@chakra-ui/react';
// import { handleImageUpload } from 'utils/handleImageUpload';
import { CloseIcon } from '@chakra-ui/icons';
import { handleMultipleImageUpload } from 'utils/handleImageUpload';

const EditStockDetails = ({ formData, setFormData }) => {
  const [imagePreview, setImagePreview] = useState([]);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (Array.isArray(formData.image) && formData.image.length > 0) {
      setImagePreview(
        formData.image.filter(
          (preview) =>
            typeof preview === 'string' &&
            preview !== '' &&
            preview !== '[object File]',
        ),
      );
    }
  }, []);

  // Handle the numeric part of the size input
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle the unit part of the size input
  const handleSizeUnitChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      size: `${prevData.size.split(' ')[0]} ${value}`, // Combine the numeric part and the unit
      sizeUnit: value, // Keep track of the unit separately
    }));
  };

  const handleRemoveImage = (index, setFormData, setImagePreview) => {
    // Update formData by replacing the removed image with an empty string
    setFormData((prevData) => {
      const updatedImages = [...prevData.image];
      if (typeof updatedImages[index] === 'string') {
        // If it's a string (existing image URL), replace it with an empty string
        updatedImages[index] = '';
      } else {
        // If it's a new file, remove it completely
        updatedImages.splice(index, 1);
      }
      return { ...prevData, image: updatedImages };
    });

    // // Remove from preview
    setImagePreview((prevPreviews) => {
      const updatedPreviews = [...prevPreviews];
      updatedPreviews.splice(index, 1);
      // Filter out empty strings and invalid file objects
      return updatedPreviews.filter(
        (preview) => preview !== '' && preview !== '[object File]',
      );
    });
  };

  return (
    <>
      <FormControl mb={3}>
        <FormLabel>Name</FormLabel>
        <Input
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Enter stock name"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Brand</FormLabel>
        <Input
          name="brand"
          value={formData.brand}
          onChange={handleInputChange}
          placeholder="Enter brand"
        />
      </FormControl>

      <FormControl isInvalid={errors.size} mb={3}>
        <FormLabel>Product Size</FormLabel>
        <Flex align="center" gap={2}>
          <Input
            name="size"
            value={formData.size.split(' ')[0]} // Show only the numeric part
            onChange={handleInputChange}
            placeholder="Enter product size"
          />
          <Select
            name="sizeUnit"
            value={formData.sizeUnit}
            onChange={handleSizeUnitChange}
          >
            <option value="ml">ml</option>
            <option value="pc">pc</option>
            <option value="gms">gms</option>
            <option value="kg">kg</option>
            <option value="time use">time use</option>
          </Select>
        </Flex>
        <FormErrorMessage>{errors.size}</FormErrorMessage>
      </FormControl>
  <FormControl mb={3}>
        <FormLabel>Position</FormLabel>
        <Input
          name="position"
          type="tel"
          value={formData.position}
          onChange={handleInputChange}
          placeholder="Enter position"
        />
      </FormControl>
      <FormControl mb={3}>
        <FormLabel>MRP</FormLabel>
        <Input
          name="mrp"
          type="number"
          value={formData.mrp}
          onChange={handleInputChange}
          placeholder="Enter MRP"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Purchasing Rate</FormLabel>
        <Input
          name="purchasingRate"
          type="number"
          value={formData.purchasingRate}
          onChange={handleInputChange}
          placeholder="Enter purchasing rate"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Barcode Number</FormLabel>
        <Input
          name="barcodeNumber"
          value={formData.barcodeNumber}
          onChange={handleInputChange}
          placeholder="Enter barcode number"
        />
      </FormControl>

      <FormControl mb={3}>
        <FormLabel>Upload Images</FormLabel>
        <Input
          name="image"
          type="file"
          accept="image/*"
          multiple
          onChange={(e) =>
            handleMultipleImageUpload(
              e,
              setErrors,
              setFormData,
              setImagePreview,
            )
          }
        />

        {imagePreview.length > 0 && (
          <Flex mt={3} gap={2} wrap="wrap">
            {imagePreview
              .filter((src) => src !== '') // Filter out empty strings and invalid file objects
              .map((src, index) => (
                <Box key={index} position="relative">
                  <Image
                    src={src}
                    alt={`Preview ${index + 1}`}
                    maxWidth="100px"
                    borderRadius="md"
                  />
                  <IconButton
                    icon={<CloseIcon />}
                    size="sm"
                    position="absolute"
                    top="-2"
                    right="-2"
                    colorScheme="red"
                    borderRadius="full"
                    onClick={() =>
                      handleRemoveImage(index, setFormData, setImagePreview)
                    }
                  />
                </Box>
              ))}
          </Flex>
        )}
      </FormControl>
    </>
  );
};

export default EditStockDetails;
