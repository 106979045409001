// import React, { useState,useEffect } from 'react';
// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   Button,
//   Input,
//   FormControl,
//   FormLabel,
//   FormErrorMessage,
//   Select,
//   HStack,
// } from '@chakra-ui/react';

// const MembershipPlanPopup = ({ isOpen, onClose, onConfirm, isLoading }) => {
//   const [formData, setFormData] = useState({
//     price: '',
//     discountedPrice: '',
//     discountPercentage: '',
//     tenure: '',
//     tenureType: 'month', // Default tenure type
//   });

//   const [errors, setErrors] = useState({
//     tenure: '',
//     price: '',
//     discountedPrice: '',
//     discountPercentage: '',
//   });

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prev) => ({ ...prev, [name]: value }));

//     if (value.trim() === '') {
//       setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
//     } else if (
//       name === 'price' ||
//       name === 'discountedPrice' ||
//       name === 'discountPercentage'
//     ) {
//       if (isNaN(value) || parseFloat(value) <= 0) {
//         setErrors((prev) => ({
//           ...prev,
//           [name]: `${name} must be a positive number`,
//         }));
//       } else {
//         setErrors((prev) => ({ ...prev, [name]: '' }));
//       }
//     } else if (name === 'tenure') {
//       if (isNaN(value) || parseFloat(value) <= 0) {
//         setErrors((prev) => ({
//           ...prev,
//           tenure: 'Tenure must be a positive number',
//         }));
//       } else {
//         setErrors((prev) => ({ ...prev, tenure: '' }));
//       }
//     } else {
//       setErrors((prev) => ({ ...prev, [name]: '' }));
//     }
//   };

//   const handleSubmit = async () => {
//     if (validateForm()) {
//       try {
//         await onConfirm(formData);
//         onClose();
//       } catch (error) {
//         console.error('Error adding membership plan:', error);
//       }
//     }
//   };

//   const validateForm = () => {
//     const newErrors = {};
//     if (!formData.tenure.trim() || parseFloat(formData.tenure) <= 0)
//       newErrors.tenure = 'Tenure is required and must be a positive number';
//     if (!formData.price.trim() || parseFloat(formData.price) <= 0)
//       newErrors.price = 'Price is required and must be a positive number';
//     if (
//       !formData.discountedPrice.trim() ||
//       parseFloat(formData.discountedPrice) <= 0
//     )
//       newErrors.discountedPrice =
//         'Discounted Price is required and must be a positive number';
//     if (
//       !formData.discountPercentage.trim() ||
//       parseFloat(formData.discountPercentage) <= 0
//     )
//       newErrors.discountPercentage =
//         'Discounted Percentage is required and must be a positive number';
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };
//   const [isFormValid, setIsFormValid] = useState(false);

//   useEffect(() => {
//     validateForm(); // Check form validity on every change
//   }, [formData]);
//   return (
//     <Modal isOpen={isOpen} onClose={onClose}>
//       <ModalOverlay />
//       <ModalContent>
//         <ModalHeader>Add New Membership Plan</ModalHeader>
//         <ModalCloseButton />
//         <ModalBody>
//           <FormControl isInvalid={!!errors.tenure} mb={3}>
//             <FormLabel>Tenure</FormLabel>
//             <HStack>
//               <Input
//                 name="tenure"
//                 type="number"
//                 value={formData.tenure}
//                 onChange={handleInputChange}
//                 placeholder="Enter tenure"
//               />
//               <Select
//                 name="tenureType"
//                 value={formData.tenureType}
//                 onChange={handleInputChange}
//               >
//                 <option value="month">Months</option>
//                 <option value="week">Weeks</option>
//                 <option value="year">Years</option>
//               </Select>
//             </HStack>
//             <FormErrorMessage>{errors.tenure}</FormErrorMessage>
//           </FormControl>

//           <FormControl isInvalid={!!errors.price} mb={3}>
//             <FormLabel>Price</FormLabel>
//             <Input
//               name="price"
//               type="number"
//               value={formData.price}
//               onChange={handleInputChange}
//               placeholder="Enter price"
//             />
//             <FormErrorMessage>{errors.price}</FormErrorMessage>
//           </FormControl>

//           <FormControl isInvalid={!!errors.discountedPrice} mb={3}>
//             <FormLabel>Discounted Price</FormLabel>
//             <Input
//               name="discountedPrice"
//               type="number"
//               value={formData.discountedPrice}
//               onChange={handleInputChange}
//               placeholder="Enter discounted price"
//             />
//             <FormErrorMessage>{errors.discountedPrice}</FormErrorMessage>
//           </FormControl>

//           <FormControl isInvalid={!!errors.discountPercentage} mb={3}>
//             <FormLabel>Discount Percentage</FormLabel>
//             <Input
//               name="discountPercentage"
//               type="number"
//               value={formData.discountPercentage}
//               onChange={handleInputChange}
//               placeholder="Enter discount percentage"
//             />
//             <FormErrorMessage>{errors.discountPercentage}</FormErrorMessage>
//           </FormControl>
//         </ModalBody>
//         <ModalFooter>
//           <Button variant="ghost" onClick={onClose}>
//             Cancel
//           </Button>
//           <Button
//             colorScheme="blue"
//             onClick={handleSubmit}
//             isLoading={isLoading}
//           >
//             Add Membership Plan
//           </Button>
//         </ModalFooter>
//       </ModalContent>
//     </Modal>
//   );
// };

// export default MembershipPlanPopup;
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  HStack,
} from "@chakra-ui/react";

const MembershipPlanPopup = ({ isOpen, onClose, onConfirm, isLoading }) => {
  const [formData, setFormData] = useState({
    price: "",
    discountedPrice: "",
    discountPercentage: "",
    tenure: "",
    tenureType: "month", // Default tenure type
  });

  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (value.trim() === "") {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else if (["price", "discountedPrice", "discountPercentage", "tenure"].includes(name)) {
      if (isNaN(value) || parseFloat(value) <= 0) {
        setErrors((prev) => ({
          ...prev,
          [name]: `${name} must be a positive number`,
        }));
      } else {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      }
    } else {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.tenure || formData.tenure.trim() === "" || parseFloat(formData.tenure) <= 0)
      newErrors.tenure = "Tenure is required and must be a positive number";
    if (!formData.price || formData.price.trim() === "" || parseFloat(formData.price) <= 0)
      newErrors.price = "Price is required and must be a positive number";
    if (!formData.discountedPrice || formData.discountedPrice.trim() === "" || parseFloat(formData.discountedPrice) <= 0)
      newErrors.discountedPrice = "Discounted Price is required and must be a positive number";
    if (!formData.discountPercentage || formData.discountPercentage.trim() === "" || parseFloat(formData.discountPercentage) <= 0)
      newErrors.discountPercentage = "Discount Percentage is required and must be a positive number";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [formData]);

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        await onConfirm(formData);
        onClose();
      } catch (error) {
        console.error("Error adding membership plan:", error);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Membership Plan</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={!!errors.tenure} mb={3}>
            <FormLabel>Tenure</FormLabel>
            <HStack>
              <Input name="tenure" type="number" value={formData.tenure} onChange={handleInputChange} placeholder="Enter tenure" />
              <Select name="tenureType" value={formData.tenureType} onChange={handleInputChange}>
                <option value="month">Months</option>
                <option value="week">Weeks</option>
                <option value="year">Years</option>
              </Select>
            </HStack>
            <FormErrorMessage>{errors.tenure}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.price} mb={3}>
            <FormLabel>Price</FormLabel>
            <Input name="price" type="number" value={formData.price} onChange={handleInputChange} placeholder="Enter price" />
            <FormErrorMessage>{errors.price}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.discountedPrice} mb={3}>
            <FormLabel>Discounted Price</FormLabel>
            <Input name="discountedPrice" type="number" value={formData.discountedPrice} onChange={handleInputChange} placeholder="Enter discounted price" />
            <FormErrorMessage>{errors.discountedPrice}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.discountPercentage} mb={3}>
            <FormLabel>Discount Percentage</FormLabel>
            <Input name="discountPercentage" type="number" value={formData.discountPercentage} onChange={handleInputChange} placeholder="Enter discount percentage" />
            <FormErrorMessage>{errors.discountPercentage}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit} isLoading={isLoading} isDisabled={!isFormValid}>
            Add Membership Plan
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MembershipPlanPopup;
