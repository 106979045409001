import { useState, useCallback } from 'react';
import axios from 'axios';
import { useLoading } from 'contexts/LoadingContext';
import { useSearch } from 'contexts/SearchContext';
import { useToast } from '@chakra-ui/react';

const useStock = () => {
  const { setIsLoading } = useLoading();
  const { searchQuery, setSearchResults } = useSearch();
  const [error, setError] = useState(null);
  const [stocks, setStocks] = useState({ data: [], currentPage: 1, totalPages: 1 });
  const [registeredStock, setRegisteredStock] = useState(null);
  const toast = useToast({ position: 'top' });

  const fetchStocks = useCallback(
    async (page = 1, query = searchQuery) => {
      setIsLoading(true);
      setError(null);

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}api/v1/admin/stock/all`,
          {
            params: { page, query },
            validateStatus: (status) => status >= 200 && status < 500,
          }
        );

        if (data?.success) {
          const result = {
            data: data.data,
            totalPages: data.pagination?.totalPages || 1,
            currentPage: page,
          };
          setStocks(result);

          // ✅ Store search results separately if query exists
          if (query) {
            setSearchResults(data.data);
          }
        } else {
          setError(data?.message || 'Failed to fetch stocks.');
        }
      } catch (err) {
        setError(err.message || 'An error occurred while fetching stocks.');
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setStocks, setError, searchQuery, setSearchResults]
  );


  const registerStock = async (formData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/stock/create`,
        // `http://localhost:5000/api/v1/admin/stock/create`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );
      // console.log(formData);

      if (response.data.success) {
        const newStock = response.data.data;
        // console.log(newStock);

        setRegisteredStock(newStock);

        toast({
          title: 'Success',
          description:
            response.data.message || 'New product added successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        setError(response.data.message || 'Failed to add new product');
        toast({
          title: 'Error',
          description: response.data.message || 'Failed to add new product',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      setError('An error occurred while adding the product. Please try again.');
      toast({
        title: 'Error',
        description:
          'An error occurred while adding the product. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    registeredStock,
    registerStock,
    fetchStocks,
    stocks,
    error,
  };
};

export default useStock;
