import React, { useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';

import { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from '@chakra-ui/icons'
import {
  Flex, 
  Box, 
  Image, 
  IconButton ,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  Textarea,
} from '@chakra-ui/react';

import 'react-datepicker/dist/react-datepicker.css';
import { handleImageUpload } from 'utils/handleImageUpload';

const OfferPopup = ({ isOpen, onClose, onConfirm, isLoading }) => {
  const datePickerRef = useRef(null);
  const [formData, setFormData] = useState({
    offerName: '',
    offerDescription: '',
    applicableOn: '',
    offerValidOn: '',
    offerType: 'percentage',
    offerValue: '',
    offerCode:'',
    offerValidity: new Date(), // Default to current date
    image: '',
  });

  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [imagePreview, setImagePreview] = useState(""); // String hona chahiye


  const validateForm = (updatedData) => {
    const newErrors = {};
    if (!updatedData?.offerName?.trim()) newErrors.offerName = 'Offer Name is required';
    if (!updatedData?.offerDescription?.trim()) newErrors.offerDescription = 'Offer Description is required';
    if (!updatedData?.applicableOn?.trim()) newErrors.applicableOn = 'Applicable On is required';
    if (!updatedData?.offerValidOn?.trim()) newErrors.offerValidOn = 'Offer Valid On is required';
    if (!updatedData?.offerValue?.trim() || isNaN(updatedData.offerValue)) newErrors.offerValue = 'Offer Value must be a number';
    if (!updatedData?.offerCode?.trim()) newErrors.offerCode = 'Offer Code is required';
    if (!updatedData?.offerValidity) newErrors.offerValidity = 'Offer Validity date is required';
    if (!updatedData.image) newErrors.image = "Image is required";
  
    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
    
    return Object.keys(newErrors).length === 0; // ✅ Return boolean
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      validateForm(updatedData); // ✅ Validate with updated state
      return updatedData;
    });
  };
  
  const formatDate = (date) => {
    if (!date) return "";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  
  const handleDateChange = (date) => {
    if (!date) return;
    
    // Store as Date object in formData
    setFormData({ ...formData, offerValidity: date });
    validateForm({ ...formData, offerValidity: date });
  };
  
  const handleSubmit = async () => {
    if (!validateForm(formData)) return;
  
    try {
      const finalFormData = new FormData();
  
      Object.entries(formData).forEach(([key, value]) => {
        if (key === "image") {
          if (value instanceof File) {
            finalFormData.append("image", value); // ✅ File properly append ho raha hai
          } 
          else {
            console.error("❌ Image is not a File instance:", value);
          }

        }
        else if (key === "offerValidity" && value) {
          // Convert date to dd/MM/yyyy format before adding
          finalFormData.append("offerValidity", formatDate(value));
        }
         else {
          finalFormData.append(key, value);
        }
      });
  
      console.log("🚀 Final FormData Entries:", [...finalFormData.entries()]); 
  
      await onConfirm(finalFormData);
      onClose();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  
  
  
  
  
  const handleRemoveImage = (index, setFormData, setImagePreview) => {
    setFormData((prevData) => {
      const updatedImage = [...prevData.image];
      updatedImage.splice(index, 1);
      return {
        ...prevData,
        image: updatedImage,
      };
    });
  
    setImagePreview((prevImage) => {
      const updatedPreviews = [...prevImage];
      updatedPreviews.splice(index, 1);
      return updatedPreviews;
    });
  }; // ✅ Close the function properly
  const date = new Date();
const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1)
  .toString()
  .padStart(2, "0")}/${date.getFullYear()}`;

console.log(formattedDate); // Output: 21/03/2025

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Offer</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          
          <FormControl isInvalid={!!errors.offerName} mb={3}>
            <FormLabel>Offer Name</FormLabel>
            <Input name="offerName" value={formData.offerName} onChange={handleInputChange} placeholder="Enter offer name" />
            <FormErrorMessage>{errors.offerName}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.offerDescription} mb={3}>
            <FormLabel>Offer Description</FormLabel>
            <Textarea name="offerDescription" value={formData.offerDescription} onChange={handleInputChange} placeholder="Enter offer description" />
            <FormErrorMessage>{errors.offerDescription}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.applicableOn} mb={3}>
            <FormLabel>Applicable On</FormLabel>
            <Select name="applicableOn" value={formData.applicableOn} onChange={handleInputChange}>
              <option value="">Select an option</option>
              <option value="wallet_booking">Wallet Booking</option>
              <option value="package_booking">Package Booking</option>
              <option value="credit_card">Credit Card</option>
              <option value="debit_card">Debit Card</option>
              <option value="upi">UPI</option>
            </Select>
            <FormErrorMessage>{errors.applicableOn}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.offerValidOn} mb={3}>
            <FormLabel>Offer Valid On</FormLabel>
            <Input name="offerValidOn" type="number" value={formData.offerValidOn} onChange={handleInputChange} placeholder="Enter applicable amount" />
            <FormErrorMessage>{errors.offerValidOn}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.offerType} mb={3}>
            <FormLabel>Offer Type</FormLabel>
            <Select name="offerType" value={formData.offerType} onChange={handleInputChange}>
              <option value="percentage">Percentage</option>
              <option value="amount">Amount</option>
              <option value="product">Product</option>
            </Select>
            <FormErrorMessage>{errors.offerType}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.offerValue} mb={3}>
            <FormLabel>Offer Value</FormLabel>
            <Input name="offerValue" type="number" value={formData.offerValue} onChange={handleInputChange} placeholder="Enter offer value" />
            <FormErrorMessage>{errors.offerValue}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.offerCode} mb={3}>
            <FormLabel>Offer Code</FormLabel>
            <Input name="offerCode" type="text" value={formData.offerCode} onChange={handleInputChange} placeholder="Enter offer Code" />
            <FormErrorMessage>{errors.offerCode}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.offerValidity} mb={3}>
            <FormLabel>Offer Validity Date</FormLabel>
            <Flex align="center" gap={2}>
            <DatePicker
  selected={formData.offerValidity}
  onChange={handleDateChange}
  minDate={new Date()}
  dateFormat="dd/MM/yyyy"
  ref={datePickerRef}
  customInput={
    <Input
      readOnly
      value={formData.offerValidity ? formatDate(formData.offerValidity) : ""}
      onClick={() => datePickerRef.current.setFocus()}
    />
  }
/>




              <IconButton icon={<CalendarIcon />} onClick={() => datePickerRef.current.setFocus()} />
            </Flex>
            <FormErrorMessage>{errors.offerValidity}</FormErrorMessage>
          </FormControl>
         
         
                   <FormControl isInvalid={!!errors.image} mb={3}>
                     <FormLabel>Upload Image</FormLabel>
                     <Input
                       name="image"
                       type="file"
                       accept="image/*"
                       onChange={(e) =>
                         handleImageUpload(e, setErrors, setFormData, setImagePreview)
                       }
                     />
                     <FormErrorMessage>{errors.image}</FormErrorMessage>
                     {imagePreview && typeof imagePreview === "string" && (
  <Image src={imagePreview} alt="Preview" maxWidth="100px" mt={3} />
)}

                   </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit} isLoading={isLoading} isDisabled={!isFormValid}>
            Add Offer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OfferPopup;
