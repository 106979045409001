// import React, { useEffect, useState, useCallback } from 'react';
// import {
//   IconButton,
//   Input,
//   InputGroup,
//   InputLeftElement,
//   useColorModeValue,
// } from '@chakra-ui/react';
// import { SearchIcon } from '@chakra-ui/icons';
// import { useSearch } from 'contexts/SearchContext';
// import { debounce } from 'lodash';
// import { useLocation } from 'react-router-dom'; // Import useLocation to detect route changes
//  // Define paths where the search bar should NOT appear
 

//  // If the current path is in hiddenRoutes, do not render the search bar

// export function SearchBar(props) {
//   const { variant, background, borderRadius, placeholder, ...rest } = props;
//   const { searchQuery, setSearchQuery, handleSearch, clearSearchResults } =
//     useSearch();
//   const searchIconColor = useColorModeValue('gray.700', 'white');
//   const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
//   const inputText = useColorModeValue('gray.700', 'gray.100');
//   const location = useLocation(); // Detect route changes

//   // Internal state to handle debounce effect
//   const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
//   const [isRouteChanging, setIsRouteChanging] = useState(false); // Track if route is changing

//   // Memoized debounced search function (only created once)
//   const debouncedSearch = useCallback(
//     debounce((query) => {
//       if (query.trim() === '') {
//         clearSearchResults();
//       } else {
//         handleSearch();
//       }
//     }, 500),
//     [clearSearchResults, handleSearch],
//   );

//   // Sync the input value with the debounced query
//   useEffect(() => {
//     // Avoid triggering search if the route has just changed
//     if (!isRouteChanging) {
//       if (debouncedQuery.trim() === '') {
//         clearSearchResults(); // Clear results if input is empty
//       } else if (debouncedQuery.trim().length >= 2) {
//         debouncedSearch(debouncedQuery); // Only trigger search when query length is >= 2
//       }
//     }

//     // Cleanup function to cancel debounce if component is unmounted
//     return () => {
//       debouncedSearch.cancel();
//     };
//   }, [debouncedQuery, clearSearchResults, debouncedSearch, isRouteChanging]);

//   // Update the debounced query when user types
//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setSearchQuery(value); // Update the search query state
//     setDebouncedQuery(value); // Update debounced state, triggering useEffect
//     setIsRouteChanging(false); // Ensure route change state is reset after typing
//   };

//   // Clear search query and results when route changes
//   useEffect(() => {
//     setIsRouteChanging(true); // Set flag for route change
//     setSearchQuery(''); // Reset search input
//     clearSearchResults(); // Clear the results
//   }, [location.pathname, setSearchQuery, clearSearchResults]); // Triggered on route change
//   const hiddenRoutes = ['/admin/complaints', '/admin/feedback', '/admin/membership', '/admin/notification', '/admin/slots', '/admin/banner'];
//   if (hiddenRoutes.includes(location.pathname)) {
//     return null;
//   }
 
//   return (
//     <InputGroup w={{ base: '100%', md: '200px' }} {...rest}>
//       <InputLeftElement
//         children={
//           <IconButton
//             bg="inherit"
//             borderRadius="inherit"
//             _hover="none"
//             _active={{
//               bg: 'inherit',
//               transform: 'none',
//               borderColor: 'transparent',
//             }}
//             _focus={{
//               boxShadow: 'none',
//             }}
//             icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
//             isDisabled // Disabled, search happens via typing
//           />
//         }
//       />
//       <Input
//         variant="search"
//         fontSize="sm"
//         bg={background ? background : inputBg}
//         color={inputText}
//         fontWeight="500"
//         _placeholder={{ color: 'gray.400', fontSize: '14px' }}
//         borderRadius={borderRadius ? borderRadius : '30px'}
//         placeholder={placeholder ? placeholder : 'Search...'}
//         value={searchQuery}
//         onChange={handleInputChange} // Handle input change and debounce search
//       />
//     </InputGroup>
//   );
// }
// import React, { useEffect, useState, useCallback } from "react";
// import {
//   IconButton,
//   Input,
//   InputGroup,
//   InputLeftElement,
//   useColorModeValue,
// } from "@chakra-ui/react";
// import { SearchIcon } from "@chakra-ui/icons";
// import { useSearch } from "contexts/SearchContext";
// import { debounce } from "lodash";
// import { useLocation } from "react-router-dom";
// const hiddenRoutes = [
//   "/admin/complaints",
//   "/admin/feedback",
//   "/admin/membership",
//   "/admin/notification",
//   "/admin/slots",
//   "/admin/banner",
// ];
// export function SearchBar(props) {
//   const { variant, background, borderRadius, placeholder, ...rest } = props;
//   const { searchQuery, setSearchQuery } = useSearch(); // ✅ handleSearch removed
//   const searchIconColor = useColorModeValue("gray.700", "white");
//   const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
//   const inputText = useColorModeValue("gray.700", "gray.100");
//   const location = useLocation();

//   const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
//  // ✅ Hide search bar on specified routes

//   // Debounce search input
//   const debouncedSearch = useCallback(
//     debounce((query) => {
//       setSearchQuery(query); // ✅ Only updating context
//     }, 500),
//     [setSearchQuery]
//   );

//   useEffect(() => {
//     debouncedSearch(debouncedQuery);

//     return () => {
//       debouncedSearch.cancel();
//     };
//   }, [debouncedQuery, debouncedSearch]);

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setDebouncedQuery(value);
//   };

//   // Route change pe search query reset
//   useEffect(() => {
//     setSearchQuery("");
//   }, [location.pathname, setSearchQuery]);
//   if (hiddenRoutes.includes(location.pathname)) {
//     return null;
//   }
//   return (
//     <InputGroup w={{ base: "100%", md: "200px" }} {...rest}>
//       <InputLeftElement>
//         <IconButton
//           bg="inherit"
//           borderRadius="inherit"
//           _hover="none"
//           _active={{
//             bg: "inherit",
//             transform: "none",
//             borderColor: "transparent",
//           }}
//           _focus={{ boxShadow: "none" }}
//           icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
//           isDisabled
//         />
//       </InputLeftElement>
//       <Input
//         variant="search"
//         fontSize="sm"
//         bg={background || inputBg}
//         color={inputText}
//         fontWeight="500"
//         _placeholder={{ color: "gray.400", fontSize: "14px" }}
//         borderRadius={borderRadius || "30px"}
//         placeholder={placeholder || "Search..."}
//         value={debouncedQuery}
//         onChange={handleInputChange} // ✅ No direct API call, only updating query
//       />
//     </InputGroup>
//   );
// }
import React, { useEffect, useState, useCallback } from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import { useSearch } from "contexts/SearchContext";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";

const hiddenRoutes = [
  "/admin/complaints",
  "/admin/feedback",
  "/admin/membership",
  "/admin/notification",
  "/admin/slots",
  "/admin/banner",
];

export function SearchBar(props) {
  const { variant, background, borderRadius, placeholder, ...rest } = props;
  const { searchQuery, setSearchQuery } = useSearch();
  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const location = useLocation();

  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 500),
    [setSearchQuery]
  );

  useEffect(() => {
    debouncedSearch(debouncedQuery);
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedQuery, debouncedSearch]);

  const handleInputChange = (e) => {
    setDebouncedQuery(e.target.value);
  };

  const clearSearch = () => {
    setDebouncedQuery("");
    setSearchQuery("");
  };

  useEffect(() => {
    setSearchQuery("");
  }, [location.pathname, setSearchQuery]);

  if (hiddenRoutes.includes(location.pathname)) {
    return null;
  }

  return (
    <InputGroup w={{ base: "100%", md: "200px" }} {...rest}>
      <InputLeftElement>
        <IconButton
          bg="inherit"
          borderRadius="inherit"
          _hover="none"
          _active={{ bg: "inherit", transform: "none", borderColor: "transparent" }}
          _focus={{ boxShadow: "none" }}
          icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
          isDisabled
        />
      </InputLeftElement>
      <Input
        variant="search"
        fontSize="sm"
        bg={background || inputBg}
        color={inputText}
        fontWeight="500"
        _placeholder={{ color: "gray.400", fontSize: "14px" }}
        borderRadius={borderRadius || "30px"}
        placeholder={placeholder || "Search..."}
        value={debouncedQuery}
        onChange={handleInputChange}
      />
      {debouncedQuery && (
        <InputRightElement>
          <IconButton
            icon={<CloseIcon w="10px" h="10px" />}
            size="xs"
            variant="ghost"
            onClick={clearSearch}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
}
