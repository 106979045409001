import React, { useEffect } from 'react';
import { Input, FormControl, FormLabel } from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';

const ResolveComplaint = ({ formData, setFormData }) => {
  // const { loggedInAdmin } = useContext(useAuth);
  const { user } = useAuth();
  // console.log(user.id);

  useEffect(() => {
    if (user) {
      setFormData((prevData) => ({ ...prevData, adminId: user.id }));
    }
  }, [user, setFormData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <>
      <FormControl mb={3}>
        <FormLabel>Closing Remark</FormLabel>
        <Input
          name="closingRemark"
          value={formData.closingRemark}
          onChange={handleInputChange}
          placeholder="Enter Closing Remark"
        />
      </FormControl>
    </>
  );
};

export default ResolveComplaint;
