import { useState, useCallback } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useLoading } from 'contexts/LoadingContext';
import { useSearch } from 'contexts/SearchContext';
const useOffer = () => {
  const [offer, setOffer] = useState({ data: [], currentPage: 1, totalPages: 1 });

  const [error, setError] = useState(null);
  const { setIsLoading } = useLoading();
  const [registeredOffer, setResgisterOffer] = useState(null);
  const toast = useToast({ position: 'top' });
  const { searchQuery, setSearchResults } = useSearch();
  
  const fetchOffer = useCallback(
    async (page = 1, query = searchQuery) => {
      setIsLoading(true);
      setError(null);

      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}api/v1/admin/offer/fetch/all`,
          {
            params: { page, query },
            validateStatus: (status) => status >= 200 && status < 500,
          }
        );

        if (data?.success) {
          const result = {
            data: data.data,
            totalPages: data.pagination?.totalPages || 1,
            currentPage: page,
          };
          setOffer(result);

          // ✅ Store search results separately if query exists
          if (query) {
            setSearchResults(data.data);
          }
        } else {
          setError(data?.message || "Failed to fetch offers.");
        }
      } catch (err) {
        setError(err.message || "An error occurred while fetching offers.");
      } finally {
        setIsLoading(false);
      }
    },
    [setIsLoading, setOffer, setError, searchQuery, setSearchResults]
  );

  const registerOffer = async (formData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/offer/create`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        const newOffer = response.data.data;
        setResgisterOffer(newOffer);

        toast({
          title: 'Success',
          description: response.data.message || 'New offer added successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        setError(response.data.message || 'Failed to add new offer');
        toast({
          title: 'Error',
          description: response.data.message || 'Failed to add new offer',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      setError('An error occurred while adding the offer. Please try again.');
      toast({
        title: 'Error',
        description:
          'An error occurred while adding the offer. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    registeredOffer,
    registerOffer,
    fetchOffer,
    offer,
    error,
  };
};

export default useOffer;
