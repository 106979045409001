'use client';
/* eslint-disable */
import { Menu, MenuButton, MenuList, MenuItem, IconButton } from '@chakra-ui/react';
import { Flex, Text, useColorModeValue, useToast,AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter,Button } from '@chakra-ui/react';
import { useDisclosure } from "@chakra-ui/react";
import { useRef } from "react";

import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import * as React from 'react';
import Card from 'components/card/Card';
import { useAuth } from 'contexts/AuthContext';
import useAdmin from 'hooks/adminApis/useAdmin';
import usePagination from 'hooks/usePagination';
import convertDateFormat from 'utils/extractDate';
import EditButton from 'components/popups/editButtonPopup/EditButton';
import useAdminActions from 'hooks/adminApis/useAdminActions';
import CreateAdmin from 'components/popups/adminPopup/AdminPopup';
import ReusableTable from 'components/reusableTable/ReusableTable';
import Pagination from '../../../../components/pagination/Pagination';
import DeleteButton from 'components/popups/deleteButtonPopup/DeleteButton';
import SwitchToggler from 'components/popups/updateStatusPopup/SwitchToggler';
import EditAdminDetails from 'components/popups/adminPopup/EditAdminDetails';
import { useSearch } from 'contexts/SearchContext';
import { FiMoreVertical } from 'react-icons/fi';

// Assets

const columnHelper = createColumnHelper();
const ITEMS_PER_PAGE = 10;

export default function ComplexTable({ isOpen, onClose }) {
  const toast = useToast({ position: 'top' });
  const { token } = useAuth();
  const { admins, error, fetchAllAdmins, registerAdmin } = useAdmin();
  const { deleteAdmin ,updateAdminDetails } = useAdminActions();
const { searchQuery, setSearchQuery, searchResults, setSearchResults, clearSearchResults } = useSearch();
  const [localSearch, setLocalSearch] = React.useState(searchQuery);
  // console.log(searchResults);

  const { currentPage} = usePagination(
    searchQuery ? searchResults.totalPages || 1 : admins?.totalPages || 1,
  );

  // React.useEffect(() => {
  //   if (token && !searchQuery) fetchAllAdmins(currentPage);
  // }, [token, currentPage, fetchAllAdmins]);
React.useEffect(() => {
  fetchAllAdmins(1, searchQuery);
}, [searchQuery]);
const handlePageChange = (newPage) => {
  fetchAllAdmins(newPage, searchQuery);
};
  const handleAddAdmin = async (formData) => {
    try {
      await registerAdmin(formData);
      await fetchAllAdmins(currentPage);
    } catch (error) {
      toast({
        title: 'Adding Failed',
        description: admins.message || 'Failed to add admin.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleEditAdmin = async (AdminId, userData) => {
    try {
      const updatedUserData = { ...userData, role: userData.role }; // Ensure role is passed
      await updateAdminDetails(AdminId, updatedUserData);
      await fetchAllAdmins(currentPage); // Fetch latest data
  
      toast({
        title: 'Admin Updated',
        description: 'Admin details updated successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: error.message || 'Failed to update admin details.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  
  const handleDelete = async (id) => {
    try {
      await deleteAdmin(id);
      await fetchAllAdmins(currentPage);
    } catch (error) {
      toast({
        title: 'Update Failed',
        description: admins.message || 'Failed to delete admin.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const data = React.useMemo(() => {
    if (searchQuery && searchResults.data?.length > 0) {
      return searchResults.data;
    }
    return admins.data || [];
  }, [admins.data, searchQuery, searchResults.data]);

  const columns = [
    columnHelper.accessor('sl. no.', {
      id: 'sl. no.',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          SL. NO.
        </Text>
      ),
      cell: ({ row }) => {
        const serialNumber = (currentPage - 1) * ITEMS_PER_PAGE + row.index + 1;
        return (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {serialNumber}
            </Text>
          </Flex>
        );
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          NAME
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('username', {
      id: 'username',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          USERNAME
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() || 'NA'}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('createdAt', {
      id: 'createdAt',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ADDED ON
        </Text>
      ),
      cell: (info) => {
        const originalDate = info.getValue();
        const formattedDate = convertDateFormat(originalDate);
        return (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {formattedDate || 'NA'}
          </Text>
        );
      },
    }),
    columnHelper.accessor('isActive', {
      id: 'isActive',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          STATUS
        </Text>
      ),
      cell: ({ row }) => (
        <Flex align="center">
          <SwitchToggler
            entityType="admin"
            entityId={row.original._id}
            initialStatus={row.original.isActive}
          />
        </Flex>
      ),
    }),
    columnHelper.accessor('action', {
      id: 'action',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '11px', lg: '13px' }}
          color={textColor}
        >
          ACTION
        </Text>
      ),
      cell: ({ row }) => {
        const { isOpen, onOpen, onClose } = useDisclosure();
        const cancelRef = useRef();
        
        return (
          <Flex align="center">
            <EditButton
              entityType="admin"
              EditComponent={EditAdminDetails}
              initialData={row.original}
              onConfirm={(updatedData) => handleEditAdmin(row.original._id, updatedData)}
            />
            <Menu strategy="fixed" closeOnSelect={false}>
              <MenuButton
                as={IconButton}
                icon={<FiMoreVertical />}
                variant="ghost"
                aria-label="Options"
              />
              <MenuList>
                {!row.original.isDeleted && (
                  <MenuItem color="red.500" onClick={onOpen}>
                    Delete
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
      
            {/* Confirmation Dialog for Deletion */}
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Confirm Delete
                  </AlertDialogHeader>
      
                  <AlertDialogBody>
                    Are you sure you want to delete this admin?
                  </AlertDialogBody>
      
                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      colorScheme="red"
                      onClick={() => {
                        handleDelete(row.original._id);
                        onClose();
                      }}
                      ml={3}
                    >
                      Delete
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Flex>
        );
      }
      
              }),
            ];
          

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error)
    return toast({
      title: 'Error',
      description: admins.message || 'Failed to fetch admins.',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        p="0px"
        maxH="60vh"
        minH="auto"
        overflowX="auto"
        overflowY="auto"
      >
        <ReusableTable
          table={table}
          data={data}
          borderColor="gray.200"
          noDataText="No records found."
          bgColor="aliceblue"
          isRowDeleted={(row) => row.original.isDeleted}
          rowBgColor="gray.200"
          rowTextColor="gray.600"
        />
        <CreateAdmin
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleAddAdmin}
        />
      </Card>
      {data.length === 0 ? (
        <></>
      ) : (
        <Pagination
          currentPage={currentPage}
          totalPages={admins?.totalPages || 1}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
