import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Image,
} from '@chakra-ui/react';
import { handleImageUpload } from 'utils/handleImageUpload';

const CategoryPopup = ({ isOpen, onClose, onConfirm, isLoading }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    position: '',
    image: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    position: '',
    image: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === 'position' && (isNaN(value) || value.trim() === '')) {
      setErrors((prev) => ({
        ...prev,
        position: 'Position must be a valid number',
      }));
    } else if (value.trim() === '') {
      setErrors((prev) => ({ ...prev, [name]: `${name} is required` }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.position.trim()) newErrors.position = 'Position is required';
    if (!formData.image) newErrors.image = 'Image is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      const finalFormData = new FormData();
      for (const key in formData) {
        finalFormData.append(key, formData[key]);
      }
      try {
        await onConfirm(formData);
        onClose();
      } catch (error) {
        console.error('Error adding partner:', error);
      }
    }
  };

  const isFormComplete =
    formData.name.trim() !== '' &&
    formData.position.trim() !== '' &&
    formData.image !== '';

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Category</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={!!errors.name} mb={3}>
            <FormLabel>Name</FormLabel>
            <Input
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter name"
            />
            <FormErrorMessage>{errors.name}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.position} mb={3}>
            <FormLabel>Position</FormLabel>
            <Input
              name="position"
              type="number"
              value={formData.position}
              onChange={handleInputChange}
              placeholder="Enter position"
            />
            <FormErrorMessage>{errors.position}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.image} mb={3}>
            <FormLabel>Upload Image</FormLabel>
            <Input
              name="image"
              type="file"
              accept="image/*"
              onChange={(e) =>
                handleImageUpload(e, setErrors, setFormData, setImagePreview)
              }
            />
            <FormErrorMessage>{errors.image}</FormErrorMessage>
            {imagePreview && (
              <Image src={imagePreview} alt="Preview" maxWidth="100px" mt={3} />
            )}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={handleSubmit}
            isLoading={isLoading}
            isDisabled={!isFormComplete} // Button disabled until all fields are filled
          >
            Add Category
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CategoryPopup;
