import { useState, useCallback } from 'react';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useAuth } from 'contexts/AuthContext';
import { useLoading } from 'contexts/LoadingContext';
import { useSearch } from 'contexts/SearchContext';

const useAdmin = () => {
  const [error, setError] = useState(null);
  const [admins, setAdmins] = useState({ data: [], currentPage: 1, totalPages: 1 });
  const { setIsLoading } = useLoading();
  const toast = useToast({ position: 'top' });
  const { token, user } = useAuth();
  const { searchQuery, setSearchResults } = useSearch();

  const fetchAllAdmins = useCallback(async (page = 1, query = searchQuery) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/all`,
        {
          params: { page, adminId: user.id, query },
          headers: { 
            'Content-Type': 'application/json', 
            Authorization: `${token}` 
          },
          validateStatus: (status) => status >= 200 && status < 500,
        }
      );

      if (response.data.success) {
        const result = {
          data: response.data.data,
          totalPages: response.data.totalPages || 1,
          currentPage: page,
        };
        setAdmins(result);

        // ✅ Store search results separately if query exists
        if (query) {
          setSearchResults(response.data.data);
        }
      } else {
        setError(response.data.message || 'Failed to fetch admins');
      }
    } catch (err) {
      setError(err.message || 'An unexpected error occurred while fetching admins.');
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, setAdmins, setError, searchQuery, setSearchResults, token, user.id]);

  const registerAdmin = async (formData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/admin/register?adminId=${user.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          validateStatus: function (status) {
            return status >= 200 && status < 500;
          },
        },
      );

      if (response.data.success) {
        toast({
          title: 'Success',
          description: 'New admin added successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        return true;
      } else {
        setError(response.data.message || 'Failed to add new admin');
        toast({
          title: 'Error',
          description: response.data.message || 'Failed to add new admin',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return false;
      }
    } catch (error) {
      // console.error('Error adding admin:', error);
      setError('An error occurred while adding the admin. Please try again.');
      toast({
        title: 'Error',
        description:
          'An error occurred while adding the admin. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    fetchAllAdmins,
    admins,
    registerAdmin,
    error,
  };
};

export default useAdmin;
